const pageTitileList = {
   '/sign-in': 'Sign in',
   '/sign-in-email': 'Sign in with email',
   '/sign-in/not-recognized': 'Not recognized',
   '/register': 'Create account',
   '/register-email': 'Create account with email',
   '/password-reset/:token': 'Choose a new password',
   '/password-reset': 'Reset your password',
   '/register/email-confirmation': 'Email verification',
   '/stories': 'Stories',
   '/stories/:id/comments': 'Comments',
   '/photosets': 'Photosets',
   '/photosets/:id': 'Photosets',
   '/videos': 'Videos',
   '/videos/:id': 'Videos',
   '/video-galleries': 'Videos',
   '/stories/:id': 'Stories',
   '/member-support': 'Member support',
   '/my-account': 'My account',
   '/my-account/change-email': 'Change email',
   '/my-account/change-password': 'Change password',
   '/my-account/membership-details': 'My membership',
   '/my-account/my-membership/upgrade-membership': 'Upgrade membership',
   '/my-account/my-membership/change-membership/:id': 'Change membership',
   '/my-account/membership-cancellation': 'Cancel membership',
   '/my-account/membership-cancelled': 'Membership cancelled',
   '/my-account/delete': 'Delete my account',
   '/my-account/notification-preferences': 'Notification preferences',
   '/join': 'Join',
   '/memberships': 'Memberships',
   '/payment-declined': 'Payment declined',
   '/memberships/terms-of-use': 'Terms of Use',
   '/payment-approved': 'Payment approved',
   '/not-found': 'Not found',
   '/404-error': '404 error',
   '/feed': 'Feed',
   '/coming-soon': 'Coming soon',
   '/store': 'Store',
   '/store/details/:id': 'Store',
   '/store/my-orders': 'My orders',
   '/store/my-orders/:id': 'My orders',
   '/contact-form': 'Contact form',
   '/contact-us': 'Contact us',
   '/my-unlocks': 'My unlocks',
   '/my-unlocks/downloads': 'My downloads',
   '/purchase/membership/:id': 'Join',
   '/purchase/video/:id': 'Video',
   '/purchase/record/:id': 'Recorded stream',
   '/purchase/pay-per-message/:id': 'PPM',
   '/purchase/photoset/:id': 'Photoset',
   '/purchase/store/:id': 'Store item',
   '/my-wallet': 'My wallet',
   '/purchase/wallet/:id': 'Wallet',
   '/tip': 'Tip',
   '/purchase/tip/:id': 'Tip',
   '/purchase/photo-unlock/:id': 'Photo',
   '/purchase/voice-unlock/:id': 'Voice',
   '/purchase/video-unlock/:id': 'Video',
   '/purchase/live-stream/:id': 'Livestream',
   '/custom-pages/:id': '',
   '/error': 'Error',
   '/livestream': 'Livestream',
   '/recorded-streams': 'Recorded streams',
   '/recorded-streams/:id': 'Recorded streams',
   '/chat-unlock-content/:id': 'Content',
   '/bundle-vault/:id': 'Bundle Vault',
   '/promotions/:id': 'Promotions',
   '/affiliates': 'Affiliates',
   '/auto-posts-notification/:id': 'Auto post notification',
   '/tip-post': 'Tip',
   '/new-join-post': 'New join',
   '/age-verification': 'Age Verification',
   '/age-verification/process': 'Age Verification',
   '/my-favorites': 'My favorites',
   '/digital-downloadabel-item-details': 'Digital downloadable item details',
   '/purchase/multiple-attachment/:id': 'Multiple attachment',
}

export { pageTitileList }
