export const POLLS_MODAL_DEFAULT_STATE = {
   isOpen: false,
   type: null,
}

const initialState = {
   pollData: null,
   pollModal: { ...POLLS_MODAL_DEFAULT_STATE },
   isFetchingPollData: true,
   isFetchingSubmitVote: false,
   pollConfirmDialog: {
      isOpen: false,
      type: null,
   },
};

export default initialState;
