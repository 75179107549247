import React from 'react';
import PropTypes from 'prop-types';
import Filter from 'common/components/responsive/filter';
import Button from 'common/components/elements/buttons/primary';
import './style.scss';
import TruncateText from 'common/components/elements/truncate-text';
import Icon from 'common/components/elements/icons';

const ViewAsSelect = ({
   isMobile,
   activeSelectedValue,
   onSimpleFilter,
   name,
   onCloseViewAs,
   onClickSelect,
   data,
   filterClassNames,
   isPublished,
   iconColor,
   isVisitorWithAgeVerification,
   onExitVisitorWithAgeVerificationMode,
}) => {
   return (
      <div className='flex items-center justify-between h-full'>
         <div className='flex flex-col'>
            <span className='text-xs config-title text-major dark:text-major-dark leading-tight mb-px'>Viewing as</span>
            {
               isVisitorWithAgeVerification ? (
                  <div
                     className='flex items-center px-4 py-2.5 rounded-lg border border-divider dark:border-divider-dark bg-panel dark:bg-panel-dark'
                  >
                     <div
                        className='mr-5 w-[104px] h-6 flex items-center justify-center rounded-full bg-secondary-12 dark:bg-secondary-dark-12'
                     >
                        <span
                           className='text-xs text-secondary dark:text-secondary-dark leading-4'
                        >
                           Visitor
                        </span>
                     </div>
                     <TruncateText
                        text='With age verification required'
                        textClass='!leading-5 text-major dark:text-major-dark'
                        textSize='16px'
                        fontWeight='400'
                     />
                     <Icon
                        name='sign-out'
                        className='ml-2 cursor-pointer'
                        color='major'
                        size='xl'
                        data-tooltip-id='ams-top-tooltip'
                        data-tooltip-content='Exit View as mode'
                        role='presentation'
                        onClick={ onExitVisitorWithAgeVerificationMode }
                     />
                  </div>
               ) : (
                  <div className='w-full z-20 view-as-filter'>
                     <Filter
                        name={ name }
                        data={ data }
                        height='10'
                        filterValueKey='slug'
                        desktopType={ isMobile ? 'mobile' : 'desktop' }
                        onFilterChange={ onSimpleFilter }
                        activeSelectedValue={ activeSelectedValue }
                        onClickSelect={ onClickSelect }
                        className={ ` w-full${ filterClassNames }` }
                        labelIcon={ !isPublished ? 'unpublish' : null }
                        labelIconColor={ iconColor }
                        isNewLabel
                     />
                  </div>
               )
            }
         </div>
         {
            !isVisitorWithAgeVerification && (
               <div className='w-6 h-6 ml-4'>
                  <Button
                     iconName='close'
                     fontIconColor='major-dark'
                     classNames='w-full h-full'
                     padding='none'
                     backgroundColor='transparent'
                     onClick={ onCloseViewAs }
                     waveColor='rgba(33, 33, 33, 0.8)'
                  />
               </div>
            )
         }
      </div>
   );
};

ViewAsSelect.propTypes = {
   onSimpleFilter: PropTypes.func,
   activeSelectedValue: PropTypes.object,
   data: PropTypes.array,
   name: PropTypes.string,
   isMobile: PropTypes.bool,
   onCloseViewAs: PropTypes.func,
   onClickSelect: PropTypes.func,
   filterClassNames: PropTypes.string,
   isPublished: PropTypes.bool,
   iconColor: PropTypes.string,
   isVisitorWithAgeVerification: PropTypes.bool,
   onExitVisitorWithAgeVerificationMode: PropTypes.func,
};

export default ViewAsSelect;
