import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';

const PollConfirmDialog = ({
   isMobile,
   type,
   onCloseModal,
}) => {

   const getModalState = () => {
      switch (type) {
         case 'unpublished_poll':
            return {
               title: 'Poll is unavailable',
               icon: 'poll',
               iconColor: 'secondary',
               withoutCancelButton: true,
               buttonText: "Ok",
               description: 'This poll is no longer available. It has already ended, and participation is now closed.',
               primaryButtonClassName: '!px-[6px]',
               action: onCloseModal,
            }

         default:
            return;
      }
   }

   const modalState = getModalState()

   if(!modalState) {
      return null
   }

   return (
      <CreateEditModal
         type='confirm'
         isMobile={ isMobile }
         onCloseModal={ onCloseModal }
         iconSize='2xl'
         { ...modalState }
      />
   )
}

PollConfirmDialog.propTypes = {
   isMobile: PropTypes.bool,
   type: PropTypes.string,
   onCloseModal: PropTypes.func,
}

export default PollConfirmDialog;
