import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import Button from 'common/components/elements/buttons/primary';
import ChooseAnswer from './modules/choose-answer';
import PollResultsStatistics from './modules/results-statistics';
import EndDateCountdown from './modules/end-date-countdown';
import Loader from 'common/components/elements/loader/circle';

const PollsModal = ({
   isMobile,
   type,
   data,
   onCloseModal,
   isAdmin = false,
   onSubmitVote = () => {},
   isFetchingSubmitVote = false,
   onClickEditVote = () => {},
   onMemberView,
   authMember,
   goToJoin,
   goToSignIn,
   isFetchingPollData,
}) => {
   const [selectedAnswerOptionId, setSelectedAnswerOptionId] = useState(data?.user_voted_option_id || null)

   useEffect(() => {
      if(onMemberView && !isFetchingPollData) {
         onMemberView()
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data, isFetchingPollData])

   const getModalState = () => {
      if(isFetchingPollData) {
         return {

         }
      }
      switch (type) {
         case 'vote':
            let isButtonDisabled = false
            let buttonText = 'Submit'

            let onClickPrimaryButton = () => onSubmitVote(selectedAnswerOptionId)

            switch (true) {
               case isAdmin:
                  isButtonDisabled = true
                  break;

               case 'not_available' === data.stream_state:
                  isButtonDisabled = false
                  buttonText = 'Get membership to vote'
                  onClickPrimaryButton = goToJoin
                  break;

               case !Boolean(authMember):
                  isButtonDisabled = false
                  buttonText = 'Sign in to vote'
                  onClickPrimaryButton = goToSignIn
                  break;


               case !Boolean(selectedAnswerOptionId) || (Boolean(selectedAnswerOptionId) && selectedAnswerOptionId === data.user_voted_option_id):
                  isButtonDisabled = true
                  break;

               default:
                  break;
            }

            let voteResult = {
               title: data.name,
               icon: 'poll',
               description: data.question,
               firstButtonProps: {
                  text: buttonText,
                  primaryColor: true,
                  backgroundColor: undefined,
                  disabled: isButtonDisabled,
                  onClick: onClickPrimaryButton,
                  isLoading: isFetchingSubmitVote,
                  dataToolTipContent: isAdmin ? 'Preview' : undefined,
               },
            }

            if(data.show_public_countdown && data.ends_in && 'published' === data.status) {
               voteResult.TopDescriptionComponent = (
                  <EndDateCountdown
                     endDate={ data.ends_in }
                  />
               )
            }

            return voteResult

         case 'admin_results':
            return {
               title: data.name,
               icon: 'poll',
               description: data.question,
               secondButtonProps: {
                  text: 'Close',
                  textColor: 'secondary',
                  onClick: onCloseModal,
               },
            }

         case 'vote_submitted':
            let result = {
               title: 'Vote submitted',
               icon: 'done',
               iconColor: 'success',
               description: data.share_results ? data.question : 'Thanks for voting!',
               secondButtonProps: {
                  text: 'Ok',
                  primaryColor: true,
                  backgroundColor: 'transparent',
                  onClick: onCloseModal,
               },
            }

            if(data.allow_edit) {
               result.firstButtonProps = {
                  text: 'Edit the vote',
                  textColor: 'secondary dark:text-secondary-dark',
                  onClick: onClickEditVote,
               }
            }

            return result;

         case 'ended_poll':

            return {
               title: 'Ended poll',
               icon: 'poll',
               iconColor: 'secondary',
               description: data.share_results ? data.question : `${ data.name } has been ended`,
               secondButtonProps: {
                  text: 'Ok',
                  primaryColor: true,
                  backgroundColor: 'transparent',
                  onClick: onCloseModal,
               },
            }

         default:
            break;
      }
   }

   const state = getModalState()

   if(!state) {
      return null
   }
   return (
      <CreateEditModal
         onCloseModal={ onCloseModal }
         type='confirm'
         withoutConfirmButton
         withoutCancelButton
         withoutCloseButton={ isAdmin ? false : isFetchingPollData }
         iconSize='2xl'
         { ...state }
         isMobile={ isMobile }
         isPreventDeafault={ false }
         modalWrapperClassName='flex flex-col'
         className='max-h-full flex-1 overflow-y-hidden'
         closeBtnClassName='!w-[fit-content] shrink-0'
      >
         {
            isFetchingPollData ? (
               <div
                  className='w-full min-h-[90px] flex items-center justify-center'
               >
                  <Loader color='primary' />
               </div>
            ) : (
               <div
                  className='w-full flex flex-col gap-8 mt-6 max-h-full flex-1 overflow-y-hidden'
               >
                  {
                     'vote' === type && (
                        <ChooseAnswer
                           answerOptions={ data.options }
                           isAdmin={ isAdmin }
                           selectedAnswerOptionId={ selectedAnswerOptionId }
                           onSelectAnswer={ (id) => setSelectedAnswerOptionId(id) }
                           isMobile={ isMobile }
                        />
                     )
                  }
                  {
                     ('admin_results' === type || (['vote_submitted', 'ended_poll'].includes(type) && Boolean(data.share_results))) && (
                        <PollResultsStatistics
                           answerOptions={ data.options }
                           userVotedOptionId={ data.user_voted_option_id }
                           isMobile={ isMobile }
                        />
                     )
                  }

                  {/* Buttons */}
                  {
                     (state.firstButtonProps || state.secondButtonProps) && (
                        <div
                           className='w-full flex flex-col items-center gap-3'
                        >
                           {
                              state.firstButtonProps && (
                                 <Button
                                    classNames='!px-[14px] !py-2 gap-2'
                                    textMarginX='0'
                                    backgroundColor='transparent'
                                    textClassName='!leading-5'
                                    borderRadius='large'
                                    textSize='small'
                                    { ...state.firstButtonProps }
                                 />
                              )
                           }
                           {
                              state.secondButtonProps && (
                                 <Button
                                    classNames='!px-[14px] !py-2 gap-2'
                                    textMarginX='0'
                                    backgroundColor='transparent'
                                    textClassName='!leading-5'
                                    borderRadius='large'
                                    textSize='small'
                                    { ...state.secondButtonProps }
                                 />
                              )
                           }
                        </div>
                     )
                  }
               </div>
            ) }
      </CreateEditModal>
   )
}

PollsModal.propTypes = {
   type: PropTypes.oneOf(['vote', 'admin_results', 'vote_submitted']),
   data: PropTypes.object,
   onCloseModal: PropTypes.func,
   isAdmin: PropTypes.bool,
   isMobile: PropTypes.bool,
   onSubmitVote: PropTypes.func,
   isFetchingSubmitVote: PropTypes.bool,
   onClickEditVote: PropTypes.func,
   onMemberView: PropTypes.func,
   authMember: PropTypes.object,
   goToJoin: PropTypes.func,
   goToSignIn: PropTypes.func,
   isFetchingPollData: PropTypes.bool,
}

export default PollsModal;
