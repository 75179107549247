export const TERMS_OF_USE = 'Terms of use';
export const RECORD_KEEPING = '2257 Compliance';
export const DMCA = 'Complaints & Content Removal';
export const PRIVACY_POLICY = 'Privacy Policy';
export const CONTACT_US = 'Contact Us';
export const COOKIE_POLICY = 'Cookie Policy';
export const GDRP_POLICY = 'GDRP Policy';
export const MEMBER_SUPPORT = 'Member support';
export const AFFILIATES = 'Affiliates';

export const types = [
   TERMS_OF_USE,
   PRIVACY_POLICY,
   RECORD_KEEPING,
   DMCA,
   AFFILIATES,
   GDRP_POLICY,
   MEMBER_SUPPORT,
   CONTACT_US,
   COOKIE_POLICY,
];
export const getInformationAndPoliciesQueryType = (type) => {
   if(!type || !types.includes(type)) return null;
   type = type.replaceAll('&', 'and')
   const typeArr = type.split(' ');
   const result = typeArr.join('-');
   return result.toLowerCase();
}
export const getInformationAndPoliciesType = (params) => {
   if(!params) return null;
   params = params.replaceAll('and', '&')
   const typeArr = params.split('-');
   const type = typeArr.join(' ');
   const result = types.find(item => item.toLowerCase() === type.toLowerCase());
   return result;
}

export const isNonIndex = (type) => {
   const keys = [
      TERMS_OF_USE,
      PRIVACY_POLICY,
      RECORD_KEEPING,
      DMCA,
      GDRP_POLICY,
      COOKIE_POLICY,
   ];

   return keys.includes(type);
}
