import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import DateFormatter from 'common/utils/DateFormatter';

const EndDateCountdown = ({
   endDate,
}) => {
   const [expireDate, setExpireDate] = useState(DateFormatter.getDateLeftInDays(endDate, true))
   let intervalRef = useRef(null)

   useEffect(() => {
      if(!endDate) return;

      intervalRef.current = setInterval(() => {
         setExpireDate(DateFormatter.getDateLeftInDays(endDate, true))
      }, 1000)

      return () => {
         if(intervalRef.current) {
            clearInterval(intervalRef.current)
         }
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [endDate])

   return (
      <div
         className='w-full min-h-[40px] flex items-center justify-center rounded-lg mt-4 bg-grey-main dark:bg-black-field'
      >
         <div
            className='flex items-center gap-2'
         >
            <Icon
               name='duration'
               size='xl'
               color='secondary'
               darkColor='secondary-dark'
            />
            <span
               className='text-major dark:text-major-dark leading-5'
            >
               Expires in {expireDate}
            </span>
         </div>
      </div>
   )
}

EndDateCountdown.propTypes = {
   endDate: PropTypes.string,
}

export default EndDateCountdown;
