import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { connect } from "react-redux";
import { siteDetailsInitOperation, checkIsCommingSoon } from 'sites/state/modules/common/operations';
import { authUserSelector, isSiteInitedSelector, siteInfoSelector, isLouisianaBlockedSelector } from 'sites/state/modules/common/selectors';
import NavigationContainer from 'sites/containers/modules/navigation';
import { push } from 'connected-react-router';
import Router from 'sites/routes/router';
import StateBlock from 'sites/views/block/state';
import Layout from 'common/layout';
import PageLoader from "common/components/modules/page-loading";

class Guest extends Component {
   static propTypes = {
      authUser: PropTypes.object,
      isSiteInited: PropTypes.bool.isRequired,
      component: PropTypes.any,
      init: PropTypes.func.isRequired,
      goToHomePage: PropTypes.func.isRequired,
      siteInfo: PropTypes.object,
      isLouisianaBlocked: PropTypes.bool,
      checkIsCommingSoon: PropTypes.func,
   };

   componentDidMount() {
      const { init, isSiteInited, checkIsCommingSoon, authUser, siteInfo } = this.props;
      if(!isSiteInited){
         init();
      } else {
         checkIsCommingSoon({ user: authUser, site_info: siteInfo })
      }
   }

   componentDidUpdate() {
      const { isSiteInited, authUser, goToHomePage } = this.props;
      if(isSiteInited && authUser && !window?.blockedAdditionalData?.additional_data?.error) {
         goToHomePage();
      }
   }

   render() {
      const {
         component: ChildComponent,
         authUser,
         isSiteInited,
         isLouisianaBlocked,
         siteInfo,
         ...rest
      } = this.props;
      if(isLouisianaBlocked){
         return (
            <StateBlock
               state='Louisiana'
               logo={ siteInfo.site_logo }
            />
         )
      }
      if(!isSiteInited){
         return (
            <PageLoader withLoader loaderColor='primary' />
         )
      }
      if(isSiteInited && !authUser) {
         return (
            <Layout>
               <div className='w-full'>
                  <NavigationContainer />
                  <Route
                     { ...rest }
                     render={ (matchProps) => {
                        return <ChildComponent { ...matchProps } />;
                     } }
                  />
               </div>
            </Layout>
         );
      }
      return null;
   }
}

const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      isSiteInited: isSiteInitedSelector(state),
      siteInfo: siteInfoSelector(state),
      isLouisianaBlocked: isLouisianaBlockedSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => ({
   init: () => {
      dispatch(siteDetailsInitOperation())
   },
   checkIsCommingSoon: (data) => {
      dispatch(checkIsCommingSoon(data))
   },
   goToHomePage: () => {
      dispatch(push(Router.route('HOMEPAGE').getCompiledPath()));
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(Guest);
