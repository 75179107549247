import PropTypes from 'prop-types';

const FooterHealthWarning = ({
   stateCode,
}) => {

   const htmlByStateCode = {
      TX: `U.S. SUBSTANCE ABUSE AND MENTAL HEALTH SERVICES ADMINISTRATION HELPLINE: 1-800-662-HELP (4357) 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 H.B.ANo.A1181 4 THIS HELPLINE IS A FREE, CONFIDENTIAL INFORMATION SERVICE (IN ENGLISH OR SPANISH) OPEN 24 HOURS PER DAY, FOR INDIVIDUALS AND FAMILY MEMBERS FACING MENTAL HEALTH OR SUBSTANCE USE DISORDERS. THE SERVICE PROVIDES REFERRAL TO LOCAL TREATMENT FACILITIES, SUPPORT GROUPS, AND COMMUNITY-BASED ORGANIZATIONS.`,
      AL: `U.S. SUBSTANCE ABUSE AND MENTAL HEALTH SERVICES ADMINISTRATION HELPLINE: <br/> 1-800-662-HELP (4357) <br/> THIS HELPLINE IS A FREE, CONFIDENTIAL INFORMATION SERVICE (IN ENGLISH OR SPANISH) OPEN 24 HOURS PER DAY, FOR INDIVIDUALS AND FAMILY MEMBERS FACING MENTAL HEALTH OR SUBSTANCE USE DISORDERS. THE SERVICE PROVIDES REFERRAL TO LOCAL TREATMENT FACILITIES, SUPPORT GROUPS, AND COMMUNITY-BASED ORGANIZATIONS.`,
   }

   if(!htmlByStateCode[stateCode]) return null

   return (
      <div
         className='w-full mb-4 md:mt-3 px-4 md:px-0 text-center'
      >
         <span
            className='text-placeholder dark:text-placeholder-dark text-xs leading-[15px] text-center'
            dangerouslySetInnerHTML={ { __html: htmlByStateCode[stateCode] } }
         />
      </div>
   )
}

FooterHealthWarning.propTypes = {
   stateCode: PropTypes.string,
}

export default FooterHealthWarning;
