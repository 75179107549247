import React, { useState, useEffect } from 'react';
import 'common/components/modules/information-and-policies/style.scss';
import Modal from 'common/components/modules/modal';
import PrimaryButton from 'common/components/elements/buttons/primary';
import { types, RECORD_KEEPING } from '../data';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import Router from 'sites/routes/router';
import { push } from 'connected-react-router';
import intercomMoreInfo, { MEMBER_SUPPORT } from 'sites/utils/IntercomMoreInfo';
// import { isLukespov, isRiggsfilms } from 'sites/utils/Helpers';
import { getSiteNameAbbreviatur, getSiteMediainName, getSiteLink } from 'common/utils/helper';
import mmsLogoLight from 'assets/images/mymembersite-logo-light.svg'
import mmsLogoDark from 'assets/images/mymembersite-logo-dark.svg'
import { useDispatch } from 'react-redux';
import rtaLogoDark from 'assets/images/rta-logo-dark.svg'
import rtaLogoLight from 'assets/images/rta-logo-light.svg'
import { filterBadWords } from 'common/utils/filter-bad-words';

const InformationPopup = ({
   showButton,
   isOpen,
   onClose,
   isMobile,
   footerPoweredByText,
   isBrandingRemoval,
   refCode,
   openSecondModal,
   isAffiliateEnabled,
   affiliateLink,
   legalPages,
   customFooterLinks,
   isAgeVerificationRequired,
}) => {
   const [openModal, setOpenModal] = useState(isOpen);
   const [animate, setAnimate] = useState('up');
   useEffect(() => {
      const timer = isOpen ? onOpenModal() : onCloseModal();
      return () => clearTimeout(timer);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [isOpen]);

   const onOpenModal = () => {
      setAnimate('up');
      return setTimeout(() => {
         setOpenModal(true)
      }, isMobile ? 100 : 0);
   }

   const onCloseModal = () => {
      setAnimate('down');
      return setTimeout(() => {
         setOpenModal(false)
      }, isMobile ? 800 : 0);
   }

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'ccbill')
   }

   const dispatch = useDispatch()

   return (
      <>
         {showButton && (
            <p
               className='font-medium text-secondary text-center py-4 information-and-policies-button select-none dark:text-secondary-dark '
               onClick={ onOpenModal }
               role='presentation'
            >
               INFORMATION & POLICIES
            </p>
         )}
         { openModal && (
            <Modal
               blurColor='modal'
               contentPosition={ isMobile ? 'bottom' : 'center' }
               onClose={ () => {
                  onCloseModal();
                  onClose();
               } }
               animate={ isMobile ?  animate : null }
            >
               <div>
                  <div className='flex justify-start items-center px-4 select-none border-b dark:border-divider-dark border-divider h-12' >
                     <div className='inline-flex mr-3'>
                        <PrimaryButton
                           iconName='close'
                           onClick={ () => {
                              onCloseModal();
                              onClose();
                           } }
                           backgroundColor='transparent'
                           fontIconColor='major'
                           padding='none'
                        />
                     </div>
                     <p className='dark:text-major-dark'>Information & Policies</p>
                  </div>
                  {
                     types.map((type, index) => {
                        if(type === "Affiliates" && !isAffiliateEnabled) {
                           return null
                        }

                        return <div
                           className='border-b border-solid dark:border-divider-dark border-divider px-4 py-3 flex justify-between cursor-pointer'
                           role='presentation'
                           onClick={ () => {

                              if(type === 'Affiliates') {
                                 window.open(affiliateLink, '_blank', 'noopener noreferrer')
                                 return
                              } else if(type === 'Contact Us') {
                                 setOpenModal(false)
                                 dispatch(push(Router.route('CONTACT_US').getCompiledPath()));
                              } else if(type === 'Member support') {
                                 setOpenModal(false)
                                 intercomMoreInfo(MEMBER_SUPPORT)
                              } else {
                                 openSecondModal(type)
                              }
                           } }
                           key={ index.toString() }
                        >
                           <p
                              data-tooltip-content={ type === RECORD_KEEPING  ? '18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement' : null }
                              data-tooltip-id={ type === RECORD_KEEPING ? 'ams-top-tooltip' : null }
                              className='text-major dark:text-major-dark font-medium'>{ type === RECORD_KEEPING ? '§2257 Compliance' : type }</p>
                           <Icon name='arrow-right' size='xl' color='secondary' darkColor='secondary-dark' />
                        </div>
                     })
                  }

                  <div className='p-4 text-placeholder dark:text-placeholder-dark leading-normal'>
                     <p className='m-0 text-sm dark:text-major-dark'>Charges through <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://support.ccbill.com/'>CCBill</a> will appear on your statement as CCBill.com *{currentInfo?.html_content ? (isAgeVerificationRequired ? filterBadWords(currentInfo?.html_content) : currentInfo?.html_content) : getSiteNameAbbreviatur()}</p>
                     <p className='m-0 text-sm dark:text-major-dark'>Charges through <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://cs.segpay.com/'>SegPay</a> will appear on your statement as {getSiteNameAbbreviatur()}</p>
                     <p className='m-0 text-sm dark:text-major-dark'>Charges through <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='https://epoch.com'>Epoch.com</a> will appear on your statement as *{getSiteMediainName()}</p>
                     {
                        customFooterLinks && customFooterLinks.map(link => {
                           return (
                              <p className='m-0 text-sm dark:text-major-dark'>
                                 <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href={ link.link }>{isAgeVerificationRequired ? filterBadWords(link.display_name) : link.display_name}</a>
                              </p>
                           )
                        })
                     }
                     {/* {
                        (isLukespov() || isRiggsfilms()) && (
                           <>
                              <p className='m-0 text-sm dark:text-major-dark'>
                                 <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='http://freeones.com/'>freeones.com</a>
                              </p>

                              <p className='m-0 text-sm dark:text-major-dark'>
                                 <a className='underline' target='_blank' rel='noopener noreferrer nofollow' href='http://babepedia.com/'>babepedia.com</a>
                              </p>

                           </>
                        )
                     } */}
                  </div>
                  {
                     !!footerPoweredByText?.trim() ? (
                        <p className='text-secondary px-4 pb-1 dark:text-secondary-dark '>{ footerPoweredByText } </p>
                     ) : (
                        <>
                           {
                              !isBrandingRemoval && (
                                 <p className='text-secondary px-4 pb-1 dark:text-secondary-dark '>
                                    {/* Powered by */}
                                    <a className='skin-primary-text font-medium flex justify-center' target='_blank' rel='noopener noreferrer' href={ `${ getSiteLink() }?ref=rf${ refCode }` }>
                                       {/* {getCapitalizeSiteName()} */}
                                       <img src={ window?.site_dark_bg ? mmsLogoDark : mmsLogoLight } className='w-[145px] h-5' alt='mmslogo' />
                                    </a>
                                 </p>
                              )
                           }
                        </>
                     )
                  }
                  <div className='pb-3 flex justify-center'>
                     <img src={ window?.site_dark_bg ? rtaLogoDark : rtaLogoLight } className='w-[42px] h-[18px]' alt='rtalogo' />
                  </div>
               </div>
            </Modal>
         )}

      </>
   )
}

InformationPopup.defaultProps = {
   showButton: true,
   isOpen: false,
   isMobile: true,
   onClose: () => {},
   refCode: '',
}

InformationPopup.propTypes = {
   showButton: PropTypes.bool,
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   isMobile: PropTypes.bool,
   footerPoweredByText: PropTypes.string,
   isBrandingRemoval: PropTypes.bool,
   refCode: PropTypes.string,
   openSecondModal: PropTypes.func,
   isAffiliateEnabled: PropTypes.bool,
   affiliateLink: PropTypes.string,
   customFooterLinks: PropTypes.array,
   legalPages: PropTypes.array,
   isAgeVerificationRequired: PropTypes.bool,
}

export default InformationPopup;
