import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../style.scss';
import InformationPopup from './mobile'
import InformationAndPoliciesDesktop from './desktop'
import { useSelector } from 'react-redux';
import QueryParams from 'sites/utils/QueryParams';
import { getInformationAndPoliciesQueryType, getInformationAndPoliciesType } from 'common/components/modules/information-and-policies/sites/data.js';
import { getSiteLink } from 'common/utils/helper';
import { getFooterHealthTextStateCode, getProtocol, getSiteDomain } from 'common/utils/utils';
import mmsLogoLight from 'assets/images/mymembersite-logo-light.svg'
import mmsLogoDark from 'assets/images/mymembersite-logo-dark.svg'
import { updateContentPageTitleForInformationAndPolicies } from 'sites/utils/utils';
import { addRobotMetaTag, removeRobotMetaTag } from 'common/utils/utils';
import { isNonIndex } from 'common/components/modules/information-and-policies/sites/data';
import FooterHealthWarning from './modules/footer-health-warning';
import classNames from 'classnames';
import LocalStorage from 'sites/utils/LocalStorage';
import SecondModal from 'common/components/modules/information-and-policies/modal';

const InformationAndPilices = ({
   showButton,
   isOpen,
   onClose,
   isButton,
   isAboutContent,
   showLinks,
   className,
   showLightLogo,
   hideInformationAndPoliciesLinks,
}) => {
   useEffect(() => {
      const queryParamsType = QueryParams.getParam('information-and-policies');
      const type = getInformationAndPoliciesType(queryParamsType)
      if(type){
         setSecondModalType(decodeURIComponent(type));
      } else if(queryParamsType){
         QueryParams.removeQueryParam('information-and-policies');
      }
   }, []);
   const [secondModalType, setSecondModalType] = useState('');

   const openSecondModal = (type) => {
      updateContentPageTitleForInformationAndPolicies(type)
      const generateQuery = getInformationAndPoliciesQueryType(type);
      if(generateQuery){
         setSecondModalType(type);

         if(isNonIndex(type))
            addRobotMetaTag();
         QueryParams.setQueryParam('information-and-policies', encodeURIComponent(generateQuery));
      } else {
         removeRobotMetaTag();
         setSecondModalType('')
         if(QueryParams.getParam('information-and-policies')){
            QueryParams.removeQueryParam('information-and-policies');
         }
      }
   }

   const footerPoweredByText = useSelector(state => state.common?.siteInfo?.footer_powered_by_text);
   const isBrandingRemoval = useSelector(state => state.common?.siteInfo?.is_branding_removal);
   const refCode = useSelector(state => state.common?.siteInfo?.referal_code)
   const protocol = getProtocol();
   const domain = getSiteDomain();
   const isAffiliateEnabled = useSelector(state => state.common?.siteInfo?.admin_enabled_affiliate)
   // const siteUuid = useSelector(state => state.common?.siteInfo?.app_id)
   const adminAffId = useSelector(state => state.common?.siteInfo?.unique_number_id)
   const subdomain = useSelector(state => state.common?.siteInfo?.subdomain)
   const legalPages = useSelector(state => state.common?.siteInfo?.legal_pages) || window?.blockedAdditionalData?.additional_info?.site_info?.legal_pages
   const whiteLabel = useSelector(state => state.common?.siteInfo?.white_label)
   const customFooterLinks = useSelector(state => state.common?.siteInfo?.custom_footer_links)

   const isAdultVerifiedStatusToken = useSelector(state => state.common?.isAdultVerified)
   const isAgeVerificationRequired = isAdultVerifiedStatusToken && isAdultVerifiedStatusToken !== 'verified' && isAdultVerifiedStatusToken !== 'unnecessary' && LocalStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true' && localStorage.getItem('IS_AGE_VERIFIED_BY_ID') !== 'true'

   const ageVerificationLocation = useSelector(state => state.common?.siteInfo?.age_verification_location)

   const footerHealthTextStateCode = getFooterHealthTextStateCode(ageVerificationLocation)

   const affiliateLink =  domain === 'mms.loc' ? `http://localhost:7202/sites/${ adminAffId }-${ subdomain }` : `${ protocol }${ `affiliate.mymember.site` }/sites/${ adminAffId }-${ subdomain }`
   if(!showLinks) {
      return (
         <div className='text-center mt-4 mb-8'>
            {
               !!(footerPoweredByText?.trim()) ? (
                  <span className='text-secondary dark:text-secondary-dark '>{footerPoweredByText} </span>
               ) : (
                  <>
                     {
                        !isBrandingRemoval && (
                           <a className='flex justify-center' target='_blank' rel='noopener noreferrer' href={ getSiteLink() }>
                              {/* {getCapitalizeSiteName()} */}
                              <img src={ window?.site_dark_bg ? mmsLogoDark : mmsLogoLight } className='w-[145px] h-5' alt='mmslogo' />
                           </a>
                        )
                     }
                  </>
               )
            }
         </div>
      )
   }
   return (
      <>
         <div
            className={ classNames({
               [`${ window?.innerWidth < 768 ? `flex mt-4` : `hidden` } justify-center items-center flex-col ${ isButton ? 'mb-14' : 'mb-0' }`]: true,
               'mb-4': hideInformationAndPoliciesLinks,
            }) }
         >
            {
               !!(footerPoweredByText?.trim()) ? (
                  <span className='text-secondary dark:text-secondary-dark '>{footerPoweredByText} </span>
               ) : (
                  <>
                     {
                        !isBrandingRemoval && (
                           <img src={ showLightLogo ? mmsLogoLight : (window?.site_dark_bg ? mmsLogoDark : mmsLogoLight) } className='w-[145px] h-5' alt='mmslogo' />
                        )
                     }
                  </>
               )
            }
            {
               !hideInformationAndPoliciesLinks && (
                  <>
                     <InformationPopup
                        showButton={ showButton }
                        isOpen={ isOpen }
                        onClose={ onClose }
                        footerPoweredByText={ footerPoweredByText }
                        isBrandingRemoval={ isBrandingRemoval }
                        refCode={ refCode }
                        openSecondModal={ openSecondModal }
                        isAffiliateEnabled={ isAffiliateEnabled }
                        affiliateLink={ affiliateLink }
                        legalPages={ legalPages }
                        customFooterLinks={ customFooterLinks }
                        isAgeVerificationRequired={ isAgeVerificationRequired }
                     />
                     {
                        Boolean(footerHealthTextStateCode) && (
                           <FooterHealthWarning
                              stateCode={ footerHealthTextStateCode }
                           />
                        )
                     }
                  </>
               )
            }
         </div>
         <div
            className={ cx({
               'md:block hidden w-full mt-auto': true,
               'information-and-polices-is-absalute': isAboutContent,
               [`${ className }`]: !!className,
            }) }
         >
            <InformationAndPoliciesDesktop
               footerPoweredByText={ footerPoweredByText }
               refCode={ refCode }
               openSecondModal={ openSecondModal }
               isAffiliateEnabled={ isAffiliateEnabled }
               affiliateLink={ affiliateLink }
               legalPages={ legalPages }
               customFooterLinks={ customFooterLinks }
               showLightLogo={ showLightLogo }
               isBrandingRemoval={ isBrandingRemoval }
               hideInformationAndPoliciesLinks={ hideInformationAndPoliciesLinks }
               isAgeVerificationRequired={ isAgeVerificationRequired }
            >
               {
                  Boolean(footerHealthTextStateCode) && !hideInformationAndPoliciesLinks && (
                     <FooterHealthWarning
                        stateCode={ footerHealthTextStateCode }
                     />
                  )
               }
            </InformationAndPoliciesDesktop>
         </div>
         {
            !!secondModalType && (
               <SecondModal
                  title={ secondModalType }
                  onClose={ () => openSecondModal(null) }
                  type='sites'
                  legalPages={ legalPages }
                  whiteLabel={ whiteLabel }
                  isAgeVerificationRequired={ isAgeVerificationRequired }
               />
            )
         }
      </>
   )
}

InformationAndPilices.defaultProps = {
   showButton: true,
   isOpen: false,
   onClose: () => {},
   isButton: false,
   showLinks: true,
   showLightLogo: false,
}

InformationAndPilices.propTypes = {
   showButton: PropTypes.bool,
   isOpen: PropTypes.bool,
   onClose: PropTypes.func,
   isButton: PropTypes.bool,
   isAboutContent: PropTypes.bool,
   showLinks: PropTypes.bool,
   className: PropTypes.string,
   showLightLogo: PropTypes.bool,
   hideInformationAndPoliciesLinks: PropTypes.bool,
}

export default InformationAndPilices;
