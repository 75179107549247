import PropTypes from 'prop-types';
import ResultItem from './result-item';
import classNames from 'classnames';

const PollResultsStatistics = ({
   answerOptions,
   userVotedOptionId,
   isMobile,
}) => {

   return (
      <div
         className={ classNames({
            'w-full flex flex-col gap-6 max-h-full flex-1 overflow-y-auto': true,
            'ams-custom-scrool': !isMobile,
         }) }
      >
         {
            answerOptions.map(option => (
               <ResultItem
                  key={ option.id }
                  data={ option }
                  userVoted={ option.id === userVotedOptionId }
               />
            ))
         }
      </div>
   )
}

PollResultsStatistics.propTypes = {
   answerOptions: PropTypes.array,
   userVotedOptionId: PropTypes.number,
   isMobile: PropTypes.bool,
}

export default PollResultsStatistics;
