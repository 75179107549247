import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import AgeVerification from 'sites/views/age-verification';
import LocalStorage from 'sites/utils/LocalStorage';
import {
   screenWidthSelector,
   siteInfoSelector,
   isSiteInitedSelector,
   isAdultVerifiedSelector,
   isPageContentLoadedForAgeVerificationModalSelector,
} from 'sites/state/modules/common/selectors'
import Router from 'sites/routes/router';
import { currentLocationPathSelector } from 'sites/state/modules/router/selectors';


const ROUTE_CODES_WHERE_WAITING_UNTIL_PAGE_LOAD = [
   'HOMEPAGE',
]

class AgeVerificationContainerContainer extends Component {
   static propTypes = {
      screenWidth: PropTypes.number,
      siteInfo: PropTypes.object,
      isSiteInited: PropTypes.bool,
      isAdultVerified: PropTypes.string,
      locationPath: PropTypes.string,
      isPageContentLoadedForAgeVerificationModal: PropTypes.bool,
   };
   state = {
      showAgeVerifiedModal: LocalStorage.getItem('age_verified') !== 'true',
   }
   agreeVerified = () => {
      LocalStorage.setItem('age_verified', true);
      this.setState({ showAgeVerifiedModal: false })
   }

   render() {
      const { showAgeVerifiedModal } = this.state;
      const {
         siteInfo,
         screenWidth,
         isSiteInited,
         isAdultVerified,
         locationPath,
         isPageContentLoadedForAgeVerificationModal,
      } = this.props

      // if site not inited, on localStorage not "age_verified" and site not on temporary mode
      if(!isSiteInited || !showAgeVerifiedModal || siteInfo.is_site_in_temporary_mode) {
         return null
      }

      // Check if on age verification routes to not show
      const isOnAgeVerificationRoutes = Router.route('AGE_VERIFICATION').match(locationPath) || Router.route('AGE_VERIFICATION_PROCESS').match(locationPath)
      if(isOnAgeVerificationRoutes) {
         return null;
      }

      // If user passed age verification
      if('verified' === isAdultVerified || LocalStorage.getItem('IS_AGE_VERIFIED_BY_ID') === 'true' || localStorage.getItem('IS_AGE_VERIFIED_BY_ID') === 'true') {
         return null;
      }

      if(ROUTE_CODES_WHERE_WAITING_UNTIL_PAGE_LOAD.some(code => Router.route(code).match(locationPath)) && !isPageContentLoadedForAgeVerificationModal) {
         return null
      }


      return (
         <AgeVerification
            agree={ this.agreeVerified }
            isMobile={ screenWidth < 701 }
            isUtahState={ siteInfo.utah_state }
            ageVerificationWarningTitle={ siteInfo.age_verification_warning_title }
            ageVerificationWarningContent={ siteInfo.age_verification_warning_content }
         />
      )


      // let component = null;
      // if(showAgeVerifiedModal && isSiteInited && !siteInfo.is_site_in_temporary_mode) {
      //    component =
      //       <AgeVerification
      //          agree={ this.agreeVerified }
      //          isMobile={ screenWidth < 701 }
      //          isUtahState={ siteInfo.utah_state }
      //       />
      // }

      // return component;
   }
}
const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
   siteInfo: siteInfoSelector(state),
   isSiteInited: isSiteInitedSelector(state),
   isAdultVerified: isAdultVerifiedSelector(state),
   locationPath: currentLocationPathSelector(state),
   isPageContentLoadedForAgeVerificationModal: isPageContentLoadedForAgeVerificationModalSelector(state),
});
export default connect(mapStateToProps)(AgeVerificationContainerContainer);
