export const TERMS_OF_USE = 'Terms of use';
export const RECORD_KEEPING = '2257 Compliance';
export const DMCA = 'Complaints & Content Removal';
export const PRIVACY_POLICY = 'Privacy Policy';
export const MEMBER_SUPPORT = 'Member support';
export const CONTACT_US = 'Contact Us';
export const AFFILIATES  = 'Affiliates';
export const COOKIE_POLICY = 'Cookie Policy';
export const GDRP_POLICY = 'GDRP Policy';

export const types = [
   TERMS_OF_USE,
   PRIVACY_POLICY,
   RECORD_KEEPING,
   DMCA,
   AFFILIATES,
   GDRP_POLICY,
   MEMBER_SUPPORT,
   CONTACT_US,
   COOKIE_POLICY,
];
