import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Router from 'sites/routes/router';
import PollsModal from 'common/components/modules/polls/polls-modal';
import { authUserSelector, screenWidthSelector } from 'sites/state/modules/common/selectors';
import { closePollModalAction, openClosePollConfirmDialogAction, setPollModalTypeAction } from 'sites/state/modules/polls/actions';
import { isFetchingPollDataSelector, isFetchingSubmitVoteSelector, pollConfirmDialogSelector, pollDataSelector, pollModalSelector } from 'sites/state/modules/polls/selectors';
import { openActivePollModalOperation, submitVoteOperation, viewPollOperation } from 'sites/state/modules/polls/operations';
import PollConfirmDialog from 'sites/views/polls/poll-confirm-dialog';
import QueryParams from 'sites/utils/QueryParams';


class PollsContainer extends Component {
   static propTypes = {
      goTo: PropTypes.func,
      screenWidth: PropTypes.number,
      pollModal: PropTypes.object,
      pollData: PropTypes.object,
      isFetchingPollData: PropTypes.bool,
      closePollModal: PropTypes.func,
      submitVote: PropTypes.func,
      isFetchingSubmitVote: PropTypes.bool,
      setPollModalType: PropTypes.func,
      viewPoll: PropTypes.func,
      authUser: PropTypes.object,
      pollConfirmDialog: PropTypes.object,
      openClosePollConfirmDialog: PropTypes.func,
      openPollModal: PropTypes.func,
   };
   state = {

   }

   componentDidMount() {
      const { authUser, openPollModal, closePollModal, openClosePollConfirmDialog } = this.props

      if(authUser) {
         let queryPollId = Number(QueryParams.getParam('poll'))

         if(!Boolean(queryPollId)) return;

         openPollModal((pollData) => {
            QueryParams.removeQueryParam('poll')
            if(pollData?.id !== queryPollId) {
               closePollModal()

               setTimeout(() => {
                  openClosePollConfirmDialog('unpublished_poll')
               })
            }
         })

      }
   }

   onMemberView = () => {
      const { pollData, viewPoll } = this.props

      if(Boolean(pollData?.id) && !pollData.member_viewed) {
         viewPoll()
      }
   }

   goToJoin = () => {
      const { pollData, goTo, closePollModal } = this.props;
      let params = `?pollId=${ pollData.id }`
      goTo(Router.route('JOIN').getCompiledPath(), params);

      closePollModal()
   }

   goToSignIn = () => {
      const { goTo, closePollModal } = this.props
      goTo(Router.route('SIGN_IN').getCompiledPath());

      closePollModal()
   }

   render() {
      const {
         screenWidth,
         pollModal,
         pollData,
         isFetchingPollData,
         closePollModal,
         submitVote,
         isFetchingSubmitVote,
         setPollModalType,
         authUser,
         pollConfirmDialog,
         openClosePollConfirmDialog,
      } = this.props;

      return (
         <>
            {
               pollModal && pollModal.isOpen && (
                  <PollsModal
                     isAdmin={ false }
                     data={ pollData }
                     type={ pollModal.type }
                     isMobile={ screenWidth <= 1024 }
                     onCloseModal={ closePollModal }
                     onSubmitVote={ submitVote }
                     isFetchingSubmitVote={ isFetchingSubmitVote }
                     onClickEditVote={ () => setPollModalType('vote') }
                     onMemberView={ this.onMemberView }
                     authMember={ authUser }
                     goToJoin={ this.goToJoin }
                     goToSignIn={ this.goToSignIn }
                     isFetchingPollData={ isFetchingPollData }
                  />
               )
            }
            {
               pollConfirmDialog && pollConfirmDialog.isOpen && (
                  <PollConfirmDialog
                     isMobile={ screenWidth <= 1024 }
                     type={ pollConfirmDialog.type }
                     onCloseModal={ () => openClosePollConfirmDialog(null) }

                  />
               )
            }
         </>
      )
   }
}
const mapStateToProps = state => ({
   screenWidth: screenWidthSelector(state),
   pollData: pollDataSelector(state),
   isFetchingPollData: isFetchingPollDataSelector(state),
   pollModal: pollModalSelector(state),
   isFetchingSubmitVote: isFetchingSubmitVoteSelector(state),
   authUser: authUserSelector(state),
   pollConfirmDialog: pollConfirmDialogSelector(state),
});
const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (path, search) => {
         dispatch(push({
            pathname: path,
            search,
         }))
      },
      closePollModal: () => dispatch(closePollModalAction()),
      submitVote: (optionId) => dispatch(submitVoteOperation(optionId)),
      setPollModalType: (type) => dispatch(setPollModalTypeAction(type)),
      viewPoll: () => dispatch(viewPollOperation()),
      openClosePollConfirmDialog: (type) => dispatch(openClosePollConfirmDialogAction(type)),
      openPollModal: (callback) => dispatch(openActivePollModalOperation(callback)),
   }
}
export default connect(mapStateToProps, mapDispatchToProps)(PollsContainer);
