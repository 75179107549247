import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import cx from 'classnames';
import GenerateColor from 'common/utils/generateTextColor.js'
import { getPrimaryColor } from 'common/utils/utils'

const IconLabel = ({
   name,
   color,
   isPrimary,
   size = 'xl',
   className = 'h-8 w-8 min-w-[32px]',
   darkIconColor,
   wrrapperBgColor,
   ...rest
}) => {
   const skinPrimaryColor = getPrimaryColor();

   const isActionPrimaryColor = skinPrimaryColor && '#231fff' === skinPrimaryColor.toLocaleLowerCase();
   const usePrimaryColor = isPrimary && !isActionPrimaryColor;
   const getBgColor = () => {
      if(usePrimaryColor){
         return {
            bgColor: null,
            iconColor: null,
            darkColor: null,
         }
      }
      if('action' === color || (isPrimary && isActionPrimaryColor)){
         return { bgColor: 'bg-action-12 dark:bg-action-dark-12', iconColor: 'action', darkColor: 'action-dark' };
      }
      return { bgColor: `bg-${ color }-12 dark:bg-${ color }-dark-12`, iconColor: color, darkColor: `${ color }-dark` };

   }

   const { bgColor, iconColor, darkColor } = getBgColor();
   return (
      <div
         { ...rest }
         style={ usePrimaryColor ? {
            backgroundColor: GenerateColor.generateRGBColor(skinPrimaryColor, 0.12),
         } : {} }
         className={ cx({
            [`${ className } flex items-center justify-center rounded-full`]: true,
            [`${ bgColor }`]: !usePrimaryColor && !wrrapperBgColor,
            [`${ wrrapperBgColor }`]: wrrapperBgColor,
         }) }>
         <Icon
            name={ name }
            size={ size }
            color={ iconColor }
            darkColor={ darkIconColor || darkColor }
            primaryColor={ usePrimaryColor ? skinPrimaryColor : null }
         />
      </div>
   );
};

IconLabel.propTypes = {
   name: PropTypes.string,
   color: PropTypes.string,
   size: PropTypes.string,
   className: PropTypes.string,
   darkIconColor: PropTypes.string,
   wrrapperBgColor: PropTypes.string,
   isPrimary: PropTypes.bool,
};

export default IconLabel;
