import toast from "common/utils/toast";
import { openActivePollModalCompleted, openActivePollModalStart, setIsFetchingSubmitVote, submitVoteCompleted, viewPollCompleted } from "./actions";
import { submitPollVote } from "sites/api/AuthApi";
import { pollDataSelector } from "./selectors";
import { getActivePoll, viewPoll } from "sites/api/MixApi";

export function openActivePollModalOperation(callback) {
   return async (dispatch) => {
      dispatch(openActivePollModalStart())
      try {
         const { data } = await getActivePoll()
         dispatch(openActivePollModalCompleted(data))

         if(callback) {
            callback(data)
         }
      } catch (error) {
         console.log(error);
      }
   }
}

export function submitVoteOperation(optionId) {
   return async (dispatch, getState) => {
      dispatch(setIsFetchingSubmitVote(true))
      try {
         const { id } = pollDataSelector(getState())
         const { data } = await submitPollVote(id, { option_id: optionId })
         dispatch(submitVoteCompleted(data))
      } catch (error) {
         console.log(error);
         toast.error('Something went wrong')
         dispatch(setIsFetchingSubmitVote(false))
      }
   }
}

export function viewPollOperation() {
   return async (dispatch, getState) => {
      try {
         const { id } = pollDataSelector(getState())
         await viewPoll(id)
         dispatch(viewPollCompleted())
      } catch (error) {
         console.log(error);
      }
   }
}
