import * as types from "./types";

const checkAndStorePoll = (pollData) => ({
   type: types.CHECK_AND_STORE_POLL,
   payload: pollData,
})

const openActivePollModalStart = () => ({
   type: types.OPEN_ACTIVE_POLL_MODAL_START,
})

const openActivePollModalCompleted = (pollData) => ({
   type: types.OPEN_ACTIVE_POLL_MODAL_COMPLETED,
   payload: { pollData },
})

const setPollModalTypeAction = (type) => ({
   type: types.SET_POLL_MODAL_TYPE,
   payload: { type },
})
const closePollModalAction = () => ({
   type: types.CLOSE_POLL_MODAL,
})
const setIsFetchingSubmitVote = (bool) => ({
   type: types.SET_IS_FETCHING_SUBMIT_VOTE,
   payload: bool,
})
const submitVoteCompleted = (updatedPoll) => ({
   type: types.SUBMIT_VOTE_COMPLETED,
   payload: { updatedPoll },
})
const viewPollCompleted = () => ({
   type: types.VIEW_POLL_COMPLETED,
})
const openClosePollConfirmDialogAction = (type = null) => ({
   type: types.OPEN_CLOSE_POLL_CONFIRM_DIALOG,
   payload: { type },
})
export {
   checkAndStorePoll,
   setPollModalTypeAction,
   closePollModalAction,
   setIsFetchingSubmitVote,
   submitVoteCompleted,
   viewPollCompleted,
   openClosePollConfirmDialogAction,
   openActivePollModalStart,
   openActivePollModalCompleted,
}
