import axios from 'axios';
import store from 'sites/state/store';
import { push } from 'connected-react-router';
import Router from 'sites/routes/router';

import { isLocalhost } from 'sites/utils/Helpers';
import { getProtocol } from 'common/utils/utils';
let apiUrl;
const protocol = getProtocol();
if(isLocalhost()) {
   apiUrl = `${ protocol }${ process.env.REACT_APP_SITES_API_ENDPOINT_FOR_DEVELOPMENT }/api`;
} else {
   const subdomain = document.querySelector('meta[name=subdomain]').content;
   apiUrl = `${ protocol }${ subdomain }.${ process.env.REACT_APP_AMS_DOMAIN }/api`;
}

const client = axios.create();

client.interceptors.request.use((config) => {
   return config;
}, (error) => {
   return Promise.reject(error);
});

client.interceptors.response.use(
   response => {
      if(response.status === 200){
         window.isBlocked = false;
         window.isVpnBlocked = false;
      }
      if(response.request.responseURL.includes('/api/auth/init') && response.status === 200){
         window.blockedAdditionalData = null
      }
      return response;
   },
   error => {
      if(error.response) {
         switch (error.response.status) {
            case 403: {
               const { response } = error
               if(response && response.data) {
                  ForbiddenError(response.data)
               }
               break;
            }
            default:{}
         }
      }
      throw error;
   }
);

function ForbiddenError(data) {
   const { error } = data
   switch (error) {
      case 'ip_blocked':{
         window.isBlocked = error;
         const { data: { ip, additional_info } } = data

         let providerQuery = `?ip=${ ip }`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'profile_blocked':{
         window.memberBanned = true;
         window.isBlocked = true
         const { data: { additional_info } } = data
         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push(Router.route('SIGN_IN').getCompiledPath()));
         break
      }
      case 'vpn_blocked':{
         window.isBlocked = true;
         window.isVpnBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&vpn_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'country_blocked':{
         window.isBlocked = true;
         window.isCountryBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&country_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      case 'region_blocked':{
         window.isBlocked = true;
         window.isRegionBlocked = true;
         const { data: { ip, additional_info  } } = data

         let providerQuery = `?ip=${ ip }&region_blocked=true`

         if(window.location.pathname.includes('/sign-in/not-recognized')) {
            providerQuery = providerQuery + '&' + window.location.search.replace('?', '')
         }

         window.blockedAdditionalData = { additional_info: additional_info }
         store.dispatch(push({
            pathname: Router.route('ERROR_PAGE').getCompiledPath(),
            search: providerQuery,
         }));
         break
      }
      default:{}
   }
}
export function login(data, signPath) {
   const signApi = signPath || 'sts/login';
   return client.post(`${ apiUrl }/v2/auth/${ signApi }`, data);
}
export function magicLoginUsingCredentials(data) {
   return client.post(`${ apiUrl }/auth/m-l-t-c`, data);
}

export function goToProviderRedirect(socialType, type, params) {
   return client.get(`${ apiUrl }/auth/${ socialType }/redirect/${ type }/${ params }`);
}

export function updatePreviewMember(data) {
   return client.post(`${ apiUrl }/auth/update-preview-member`, data);
}

export function magicLogin(data) {
   return client.post(`${ apiUrl }/auth/m-l-t`, data);
}

export function register(data) {
   return client.post(`${ apiUrl }/auth/register`, data);
}
export function sendEmailCode(data) {
   return client.post(`${ apiUrl }/auth/verify`, data);
}
export function resendCode(data) {
   return client.post(`${ apiUrl }/auth/resend-link`, data);
}

// RESET PASSWORD start
export function  passwordResetLink(email) {
   return client.post(`${ apiUrl }/auth/password-reset/email`, { email });
}

export function resetPassword(data) {
   return client.post(`${ apiUrl }/auth/password-reset`, data);
}
// RESET PASSWORD end

export function createAffiliateStatistics(code) {
   return client.post(`${ apiUrl }/auth/create-statistic`, { code })
}
export function visitorSendContactUsMessage(data) {
   return client.post(`${ apiUrl }/contact-us/visitor-send-message`, data);
}

export function getObjectUrl(paths = [], prefix = null) {
   const formattedPaths = paths.map((path) => `paths[]=${ path }`);
   let uri = `${ apiUrl }/get-object-url?${ formattedPaths.join('&') }`;
   if(prefix) {
      uri += `&pathName=${ prefix }`;
   }
   return client.get(uri);
}
