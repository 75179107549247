import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const ModalContent = ({
   position = 'bottom',
   children,
   bgColor = 'panel',
   roundedModal = 'xlg',
   marginX,
   animate,
   contentWidth,
   otherClassName,
}) => {

   const style = {};
   if(contentWidth) {
      style.width = contentWidth;
   }
   return (
      <div
         className={ cx({
            'bg-transparent w-full h-full flex': true,
            'items-end': position === 'bottom',
            'items-center justify-center': position === 'center' || position === 'image',
            'todo-left': position === 'left',
            'todo-tooltip': position === 'tooltip',
            'sm:items-center sm:justify-center items-end': position === 'responsive',
         }) }
      >
         <div
            className={ cx(`modal-content ${ otherClassName ? otherClassName : '' }`, {
               [`bg-${ bgColor } overflow-y-auto z-10 w-full`]: position !== 'image',
               [`rounded-t-${ roundedModal } shadow dark:shadow-dark border dark:border-divider-dark border-divider`]: position === 'bottom',
               [`rounded-${ roundedModal } mx-${ marginX } shadow dark:shadow-dark sm:w-116 w-full`]: position === 'center',
               [`sm:rounded-${ roundedModal } rounded-t-${ roundedModal } sm:mx-${ marginX } mx-0 shadow dark:shadow-dark sm:w-116 w-full`]: position === 'responsive',
               'h-full w-full p-4 flex items-center': position === 'image',
               [`animated-${ animate }`]: !!animate,
               'dark:bg-panel-dark': 'panel' === bgColor,
            }) }
            style={ style }
         >
            { children }
         </div>
      </div>
   )
};


ModalContent.propTypes = {
   position: PropTypes.oneOf([
      'bottom',
      'center',
      'left',
      'tooltip',
      'image',
      'full-screen',
      'responsive',
   ]),
   bgColor: PropTypes.string,
   roundedModal: PropTypes.string,
   children: PropTypes.any,
   marginX: PropTypes.string,
   animate: PropTypes.oneOf(['up', 'down']),
   contentWidth: PropTypes.any,
   otherClassName: PropTypes.string,
};

export default memo(ModalContent);
