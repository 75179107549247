import PropTypes from 'prop-types';
import classNames from 'classnames';


const ResultItem = ({
   data,
   userVoted,
}) => {

   return (
      <div
         className='w-full flex flex-col gap-2'
      >
         <div
            className='w-full flex justify-between gap-3'
         >
            <span
               className='text-start text-major dark:text-major-dark leading-6'
            >
               {data.name}
            </span>
            <span
               className='text-start text-major dark:text-major-dark leading-6 whitespace-nowrap'
            >
               {data.percentage}%
            </span>
         </div>
         <div
            className='w-full h-2 rounded-lg bg-[#F8F9FA]'
         >
            <div
               className={ classNames({
                  'h-full rounded-lg': true,
                  'bg-success dark:bg-success-dark': userVoted,
                  'bg-pending dark:bg-pending-dark': !userVoted,
               }) }
               style={ {
                  width: `${ data.percentage }%`,
               } }
            />
         </div>
      </div>
   )
}

ResultItem.propTypes = {
   data: PropTypes.object,
   userVoted: PropTypes.bool,
}

export default ResultItem;
