import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import Content from './content';

const Modal = ({
   children,
   contentPosition,
   blurColor = '',
   contentBgColor,
   roundedModal,
   className = 'h-full',
   marginX = '8',
   animate,
   contentWidth,
   otherClassName,
   fullScreenMode,
   onClose,
   closeOnClickOutside = true,
   isCloseAction = true,
}) => {
   const modalRootRef = useRef();
   const idRef = useRef();
   const elRef = useRef();
   const [mounted, setMounted] = React.useState(false);


   const destorModalState = () => {
      if(modalRootRef.current && elRef.current) {
         modalRootRef.current.removeChild(elRef.current)
      }
      if(getModalsCount() === 1) {
         const body = getBody();
         const root = getRoot()
         if(root){
            root.removeAttribute('style');
         }
         if(body) {
            body.classList.remove('body_non_scroll');
            try {
               body.removeChild(modalRootRef.current);
            } catch (e) { }

         }
      }
      if(fullScreenMode) {
         closeFullScreen();
      }
      removeIdFromSuperGlobals();
   }

   useEffect(() => {
      if(mounted) {
         destorModalState();
         return
      }
      setMounted(true);
      const body = getBody();
      if(body) {
         modalRootRef.current = getModalRoot();
         body.classList.add('body_non_scroll');
         if(!modalRootRef.current && isHasDocument()) {
            modalRootRef.current = document.createElement('div');
            modalRootRef.current.id = 'modals-root';
            body.appendChild(modalRootRef.current);
         }
         if(!window.activeModals) {
            window.activeModals = {};
         }
         idRef.current = generateUniqueId();
         if(isHasDocument()) {
            elRef.current = document.createElement('div')
            elRef.current.className = 'relative w-full h-full';
            elRef.current.style.zIndex = getZindex();
            elRef.current.addEventListener('click', onClick, false);
         }

         addIdIntoSuperGlobals();
         const root = getRoot()
         if(root) {
            root.style.zIndex = 1;
         }
         if(modalRootRef.current && elRef.current) {
            modalRootRef.current.appendChild(elRef.current);
         }
         if(fullScreenMode) {
            openFullscreen();
         }
      }
      return () => {
         setMounted(false);
         destorModalState();

      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getScreenWidth = () => {
      if('undefined' !== typeof window) {
         return window.screenWidth
      }
      return 1024;
   };

   const isHasDocument = () => {
      return 'undefined' !== typeof document;
   };

   const getBody = () => {
      if(isHasDocument()) {
         return document.querySelector('body');
      }
      return null;
   };

   const getRoot = () => {
      if(isHasDocument()) {
         return document.querySelector('#root');
      }
      return null;
   };

   const getModalRoot = () => {
      if(isHasDocument()) {
         return document.getElementById('modals-root');
      }
      return null;
   };

   const getActiveModals = () => {
      if('undefined' !== typeof window) {
         return window.activeModals;
      }
      return {};
   };

   const removeIdFromSuperGlobals = () => {
      if(idRef.current){
         delete window.activeModals[idRef.current];
      }
   };

   const addIdIntoSuperGlobals = () => {
      if(idRef.current){
         window.activeModals[idRef.current] = true;

      }
   };

   const getModalsCount = () => {
      const activeModals = getActiveModals();

      return Object.keys(activeModals).length;
   };

   const closeFullScreen = async () => {
      if(!isHasDocument()) return
      try {
         if(document.exitFullscreen) {
            await document.exitFullscreen();
         } else if(document.mozCancelFullScreen) { /* Firefox */
            await document.mozCancelFullScreen();
         } else if(document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            await document.webkitExitFullscreen();
         } else if(document.msExitFullscreen) { /* IE/Edge */
            await document.msExitFullscreen();
         }
      } catch (e) {
         //
         console.log(e)
      }
   };

   const openFullscreen = async () => {
      if(!isHasDocument()) return
      try {
         const elem = document.documentElement;
         if(elem.requestFullscreen) {
            await elem.requestFullscreen();
         } else if(elem.mozRequestFullScreen) { /* Firefox */
            await elem.mozRequestFullScreen();
         } else if(elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            await elem.webkitRequestFullscreen();
         } else if(elem.msRequestFullscreen) { /* IE/Edge */
            await elem.msRequestFullscreen();
         }
      } catch (e) {
         //
         console.log(e)
      }
   };

   const generateUniqueId = () => {
      return `_${ Math.random().toString(36).substr(2, 9) }`;
   };

   const getZindex = () => {
      const activeModals = getActiveModals();
      return Object.keys(activeModals).length + 1;
   };

   const onClick = e => {
      const screenWidth = getScreenWidth();

      if(closeOnClickOutside && e.target.closest('.modal-content') === null && typeof onClose === 'function') {
         if(isCloseAction) {
            onClose(e);
         }
         if(!isCloseAction && screenWidth < 701) {
            onClose(e);
         }
      }
      // Close modal on click outside
   };

   if(!mounted || !elRef.current) return null;

   elRef.current.className = `${ blurColor === '18-plus-age-modal' ? `bg-${ blurColor }` : 'bg-modal-cover dark:bg-modal-cover-dark' }  absolute w-full  ${ className } backdrop-blur-[8px] `;

   return ReactDOM.createPortal(
      <Content
         bgColor={ contentBgColor }
         position={ contentPosition }
         roundedModal={ roundedModal }
         marginX={ marginX }
         animate={ animate }
         contentWidth={ contentWidth }
         otherClassName={ otherClassName }
      >
         {children}
      </Content>
      ,
      elRef.current
   );
};

Modal.propTypes = {
   children: PropTypes.node,
   contentPosition: PropTypes.oneOf([
      'bottom',
      'center',
      'tooltip',
      'left',
      'image',
      'full-screen',
      'responsive',
   ]),
   onClose: PropTypes.func.isRequired,
   closeOnClickOutside: PropTypes.bool,
   blurColor: PropTypes.string,
   contentBgColor: PropTypes.string,
   roundedModal: PropTypes.string,
   className: PropTypes.string,
   fullScreenMode: PropTypes.bool,
   marginX: PropTypes.string,
   animate: PropTypes.oneOf(['up', 'down']),
   contentWidth: PropTypes.any,
   isCloseAction: PropTypes.bool,
   otherClassName: PropTypes.string,
}


export default Modal;
