import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { screenWidthSelector, siteInfoSelector, authUserSelector, viewAsModalSelector } from 'sites/state/modules/common/selectors';
import { setViewAsModal } from 'sites/state/modules/common/actions';
import { loginAsOperation, siteDetailsInitOperation } from 'sites/state/modules/common/operations';
import ViewAs from 'sites/views/view-as';
import LocalStorage from 'sites/utils/LocalStorage';
import QueryParams from 'sites/utils/QueryParams';
class ViewAsContainer extends Component {
   static propTypes = {
      siteInfo: PropTypes.object.isRequired,
      authUser: PropTypes.object,
      screenWidth: PropTypes.number,
      loginAs: PropTypes.func,
      setViewAsModal: PropTypes.func,
      viewAsModal: PropTypes.object,
      siteDetailsInit: PropTypes.func,
   }
   constructor(){
      super()
      this.state = {
         isShowViewAs: false,
         type: null,
         id: null,
      }
   }

   componentDidMount(){
      const { authUser, setViewAsModal } = this.props;
      let showViewAs = false;
      let type = null
      let pricingId = null;
      let hideLoginAs = LocalStorage.getItem('hide-login-as');
      if(authUser && authUser.fake && authUser.fake === true) {
         if(!hideLoginAs) {
            showViewAs = true;
         }
         type = authUser.membership_status;
         pricingId = authUser.pricing_id;
         if(type === 'incomplete') {
            type = 'guest'
         }
      }
      if(Boolean(LocalStorage.getItem('view-as-age-verification-required'))) {
         showViewAs = true;
         type = 'visitor_with_age_verification'
      }
      this.setState({
         isShowViewAs: !!showViewAs,
         type: type,
         id: pricingId,
      })
      const modalType = QueryParams.getParam('open-modal');
      if('view-as' === modalType)  {
         setViewAsModal({ isOpen: true });
      } else {
         setViewAsModal({ isOpen: false });
      }
   }

   onAddCloseModal = data => {
      const { setViewAsModal } = this.props;

      if(data.isOpen) {
         QueryParams.addQueryParam('open-modal', 'view-as');
      } else {
         QueryParams.removeQueryParam('open-modal');
      }
      setViewAsModal(data)
   }


   componentDidUpdate(prevProps, prevState) {
      const { authUser  } = this.props;
      let showViewAs = false;
      let type = null
      let pricingId = null;
      let hideLoginAs = LocalStorage.getItem('hide-login-as');
      if(authUser && authUser.fake && authUser.fake === true) {
         if(!hideLoginAs) {
            showViewAs = true;
         }
         type = authUser.membership_status;
         pricingId = authUser.pricing_id;
         if(type === 'incomplete') {
            type = 'guest'
         }
      }
      if(Boolean(LocalStorage.getItem('view-as-age-verification-required'))) {
         showViewAs = true;
         type = 'visitor_with_age_verification'
      }
      if(prevState.isShowViewAs !== !!showViewAs) {
         this.setState((oldState) => {
            return {
               ...oldState,
               isShowViewAs: !!showViewAs,
               type: type,
               id: pricingId,
            }
         })
      }
   }

   onCloseViewAs = () => {
      if(Boolean(LocalStorage.getItem('view-as-age-verification-required'))) {
         return;
      }
      LocalStorage.setItem('hide-login-as', true);
      this.setState({ isShowViewAs: false })
   }

   onExitVisitorWithAgeVerificationMode = () => {
      if(!Boolean(LocalStorage.getItem('view-as-age-verification-required'))) {
         return;
      }
      const { siteDetailsInit } = this.props
      LocalStorage.removeItem('view-as-age-verification-required')
      siteDetailsInit()
   }


   render(){
      const {
         siteInfo,
         screenWidth,
         loginAs,
         viewAsModal,
      } = this.props;
      const { isShowViewAs, id, type } = this.state;
      if(isShowViewAs) {
         return (
            <ViewAs
               viewAsModal={ viewAsModal }
               setViewAsModal={ this.onAddCloseModal }
               pricings={ siteInfo.pricings }
               goToView={ data => {
                  loginAs(data);
                  QueryParams.removeQueryParam('open-modal');
               } }
               isMobile={ screenWidth < 701 }
               onCloseViewAs={ this.onCloseViewAs }
               id={ id }
               type={ type }
               onExitVisitorWithAgeVerificationMode={ this.onExitVisitorWithAgeVerificationMode }
            />
         );
      }
      return '';
   }

}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      siteInfo: siteInfoSelector(state),
      authUser: authUserSelector(state),
      viewAsModal: viewAsModalSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      setViewAsModal: (bool) => {
         dispatch(setViewAsModal(bool));
      },
      loginAs: (data) => {
         dispatch(loginAsOperation(data));
      },
      siteDetailsInit: () => {
         dispatch(siteDetailsInitOperation())
      },
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewAsContainer);
