import React from 'react';
import PropTypes from 'prop-types';
import ViewAsContent from 'common/components/modules/view-as';
import './style.scss';

const ViewAs = ({
   isMobile,
   pricings,
   goToView,
   viewAsModal,
   setViewAsModal,
   onCloseViewAs,
   id,
   type,
   onExitVisitorWithAgeVerificationMode,
}) => {
   return (
      <div className='w-full h-18 site-view-as fixed z-30 px-4'>
         <ViewAsContent
            isSelect={ true }
            isOpenModal={ viewAsModal.isOpen }
            pricings={ pricings }
            isMobile={ isMobile }
            goToView={ goToView }
            id={ id }
            type={ type }
            onCloseViewAs={ onCloseViewAs }
            onCloseModal={ () => setViewAsModal({ isOpen: false }) }
            setOpenModal={ () => setViewAsModal({ isOpen: true }) }
            onExitVisitorWithAgeVerificationMode={ onExitVisitorWithAgeVerificationMode }
         />
      </div>
   )
};

ViewAs.propTypes = {
   setViewAsModal: PropTypes.func,
   goToView: PropTypes.func,
   onCloseViewAs: PropTypes.func,
   pricings: PropTypes.array,
   viewAsModal: PropTypes.object,
   isMobile: PropTypes.bool,
   id: PropTypes.number,
   type: PropTypes.string,
   onExitVisitorWithAgeVerificationMode: PropTypes.func,
};

export default React.memo(ViewAs);
