import classNames from 'classnames';
import RadioLabel from 'common/components/elements/inputs/radio-label';
import PropTypes from 'prop-types';

const ChooseAnswer = ({
   answerOptions,
   isAdmin,
   selectedAnswerOptionId,
   onSelectAnswer,
   isMobile,
}) => {

   return (
      <div
         className={ classNames({
            'w-full flex flex-col py-1 rounded-lg border border-divider dark:border-divider-dark max-h-full flex-1 overflow-y-auto': true,
            'ams-custom-scrool': !isMobile,
         }) }
      >
         {
            answerOptions.map(option => (
               <RadioLabel
                  key={ option.id }
                  labelType='html'
                  label={ option.name }
                  id={ `answer_option_${ option.id }` }
                  paddingY='2.5'
                  htmlLabelWrapperClassName='leading-5 text-start text-major dark:text-major-dark'
                  disabled={ isAdmin }
                  className={ classNames({
                     'hover:bg-hover dark:hover:bg-hover-dark': !isAdmin,
                     '!cursor-default': isAdmin,
                  }) }
                  onChange={ () => onSelectAnswer(option.id) }
                  checked={ option.id === selectedAnswerOptionId }
                  radioButtonTooltipText={ isAdmin ? 'Preview' : undefined }
               />
            ))
         }
      </div>
   )
}

ChooseAnswer.propTypes = {
   answerOptions: PropTypes.array,
   isAdmin: PropTypes.bool,
   selectedAnswerOptionId: PropTypes.string,
   onSelectAnswer: PropTypes.func,
   isMobile: PropTypes.bool,
}

export default ChooseAnswer;
