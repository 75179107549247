import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import './index.scss';

const Loader = ({
   className = '',
   color,
   size = 10,
   customColor = false,
   ...props
}) => {
   return (
      <div
         { ...props }
         className={ ClassNames(
            {
               [`circle-loader border-4 rounded-full w-${ size } h-${ size }`]: true,
               'skin-secondary-border': !['white', 'black', 'primary', 'warning', 'error'].includes(color) && !customColor,
               'border-major-dark dark:border-major-dark': color === 'white',
               'border-major dark:border-major': color === 'black',
               'border-warning dark:border-warning-dark': color === 'warning',
               'border-error dark:border-error-dark': color === 'error',
               'skin-primary-border': color === 'primary',
               [`${ className }`]: !!className,
            })
         }
      />

   );
};


Loader.propTypes = {
   className: PropTypes.string,
   color: PropTypes.string,
   size: PropTypes.any,
   customColor: PropTypes.bool,
};

export default Loader;
