import videojs from 'video.js';
import SitesAuth from 'sites/utils/Auth';
import AdminAuth from 'admin/utils/Auth';

// Constants
export const DRM_TYPES = {
   Widevine: {
      name: 'Widevine',
      mediaKey: 'com.widevine.alpha',
   },
   PlayReady: {
      name: 'PlayReady',
      mediaKey: 'com.microsoft.playready',
   },
   FairPlay: {
      name: 'FairPlay',
      mediaKey: 'com.apple.fps.1_0',
   },
};

// TODO_DRM based on this constant we use token-based or proxy-based license issueance
export const IS_PROXY_BASED_LICENSE_ISSUANCE = true

const LICENSE_URI = 'https://license-global.pallycon.com/ri/licenseManager.do';

const SITE_ID = 'QU4X';

const WIDEVINE_CERT_URI = 'https://license-global.pallycon.com/ri/widevineCert.do?siteId=' + SITE_ID; // for cert

var FAIR_PLAY_CERT_URI = 'https://license-global.pallycon.com/ri/fpsKeyManager.do?siteId=' + SITE_ID; // for base64 encoded binary cert data


function base64DecodeUint8Array(input) {
   var raw = window.atob(input);
   var rawLength = raw.length;
   var array = new Uint8Array(new ArrayBuffer(rawLength));

   for(let i = 0; i < rawLength; i++)
      array[i] = raw.charCodeAt(i);

   return array;
}

function base64EncodeUint8Array(input) {
   var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
   var output = "";
   var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
   var i = 0;

   while(i < input.length) {
      chr1 = input[i++];
      chr2 = i < input.length ? input[i++] : Number.NaN;
      chr3 = i < input.length ? input[i++] : Number.NaN;

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if(isNaN(chr2)) {
         enc3 = enc4 = 64;
      } else if(isNaN(chr3)) {
         enc4 = 64;
      }
      output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
       keyStr.charAt(enc3) + keyStr.charAt(enc4);
   }
   return output;
}

function arrayToString(array) {
   var uint16array = new Uint16Array(array.buffer);
   return String.fromCharCode.apply(null, uint16array);
}

// get widevine certificate base64 encoded data
const getWidevineCertBase64 = async () => {
   let widevineCert;
   const response = await fetch(WIDEVINE_CERT_URI);
   await response.body
      .getReader()
      .read()
      .then((res) => {
         widevineCert = btoa(String.fromCharCode(...new Uint8Array(res.value)))
      });
   return widevineCert;
}

export const getSupportedDrmType = async () => {
   if(window.supportedDrmType) {
      return window.supportedDrmType
   }
   const config = [
      {
         initDataTypes: ['cenc'],
         audioCapabilities: [
            {
               contentType: 'audio/mp4;codecs="mp4a.40.2"',
            },
         ],
         videoCapabilities: [
            {
               contentType: 'video/mp4;codecs="avc1.42E01E"',
            },
         ],
      },
   ];

   let supportedDrmType;

   for(const key in DRM_TYPES) {
      try {
         await navigator.requestMediaKeySystemAccess(
            DRM_TYPES[key].mediaKey,
            config
         );

         supportedDrmType = DRM_TYPES[key].name;
      } catch (error) {
         console.log(key + ' :: ' + error);
      }
   }

   window.supportedDrmType = supportedDrmType;
   return supportedDrmType;
}


export const getDrmPlayerConfig = async ({
   supportedDrmType,
   hlsUri,
   dashUri,
   licenseToken,
   getDrmVideoLicenseProxyUrl,
   isTokenBasedLicenseIssuance,
}) => {
   let authToken;
   if(window.location.origin.includes('dashboard')) {
      authToken = `Bearer ${ AdminAuth.getToken() }`
   } else {
      authToken = `Bearer ${ SitesAuth.getToken() }`
   }

   let playerConfig;

   switch (supportedDrmType) {
      case 'Widevine':
         const widevineCert = await getWidevineCertBase64()

         let licenseIssuanceConfig = {
            url: LICENSE_URI,
            licenseHeaders: {
               'pallycon-customdata-v2': licenseToken,
            },
         }

         if(IS_PROXY_BASED_LICENSE_ISSUANCE && !isTokenBasedLicenseIssuance) {
            licenseIssuanceConfig = {
               url: getDrmVideoLicenseProxyUrl(),
               licenseHeaders: {
                  'Authorization': authToken,
               },
            }
         }

         playerConfig = {
            src: dashUri,
            type: 'application/dash+xml',
            keySystems: {
               "com.widevine.alpha": {
                  serverURL: LICENSE_URI,
                  serverCertificate: widevineCert,
                  persistentState: 'required',
                  ...licenseIssuanceConfig,
               },
            },
         }
         break;

      case 'PlayReady':

         let playReadyLicenseIssuanceConfig = {
            url: LICENSE_URI,
            licenseHeaders: {
               'pallycon-customdata-v2': licenseToken,
            },
         }

         if(IS_PROXY_BASED_LICENSE_ISSUANCE && !isTokenBasedLicenseIssuance) {
            playReadyLicenseIssuanceConfig = {
               url: getDrmVideoLicenseProxyUrl(),
               licenseHeaders: {
                  'Authorization': authToken,
               },
            }
         }

         playerConfig = {
            src: dashUri,
            type: 'application/dash+xml',
            keySystems: {
               "com.microsoft.playready": {
                  serverURL: LICENSE_URI,
                  ...playReadyLicenseIssuanceConfig,
               },
            },
         }
         break;

      case 'FairPlay':


         let fairPlayLicenseIssuanceConfig = {
            getLicense: function(emeOptions, contentId, keyMessage, callback) {
               console.log('getLicense');
               videojs.xhr({
                  url: LICENSE_URI,
                  method: 'POST',
                  responseType: 'text',
                  body: 'spc=' + base64EncodeUint8Array(keyMessage),
                  headers: {
                     'Content-type': 'application/x-www-form-urlencoded',
                     'pallycon-customdata-v2': licenseToken,
                  },
               }, function(err, response, responseBody) {
                  if(err) {
                     callback(err)
                     return
                  }
                  callback(null, base64DecodeUint8Array(responseBody))
               })
            },
         }

         if(IS_PROXY_BASED_LICENSE_ISSUANCE && !isTokenBasedLicenseIssuance) {
            fairPlayLicenseIssuanceConfig = {
               getLicense: async function(emeOptions, contentId, keyMessage, callback) {
                  videojs.xhr({
                     url: getDrmVideoLicenseProxyUrl(),
                     method: 'POST',
                     responseType: 'text',
                     body: 'spc=' + base64EncodeUint8Array(keyMessage),
                     headers: {
                        'Content-type': 'application/x-www-form-urlencoded',
                        'Authorization': authToken,
                     },
                  }, function(err, response, responseBody) {
                     if(err) {
                        callback(err)
                        return
                     }
                     callback(null, base64DecodeUint8Array(responseBody))
                  })
               },
            }
         }

         playerConfig = {
            src: hlsUri,
            type: 'application/x-mpegurl',
            keySystems: {
               "com.apple.fps.1_0": {
                  getCertificate: function(emeOptions, callback) {
                     console.log('getCertificate');
                     videojs.xhr({
                        url: FAIR_PLAY_CERT_URI,
                        method: 'GET',
                     }, function(err, response, responseBody) {
                        if(err) {
                           callback(err)
                           return
                        }
                        callback(null, base64DecodeUint8Array(responseBody));
                     })
                  },
                  getContentId: function(emeOptions, initData) {
                     console.log('getContentId');
                     const contentId = arrayToString(initData);
                     return contentId.substring(contentId.indexOf('skd://') + 6);
                  },
                  ...fairPlayLicenseIssuanceConfig,
               },
            },
         }
         break;

      default:
         break;
   }

   return playerConfig

}


