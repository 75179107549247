import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route } from 'react-router';
import { connect } from "react-redux";
import { siteDetailsInitOperation } from 'sites/state/modules/common/operations';
import {
   isSiteInitedSelector,
   authUserSelector,
   isLouisianaBlockedSelector,
   siteInfoSelector,
   isFetchingLogoutSelector,
} from 'sites/state/modules/common/selectors';
import StateBlock from 'sites/views/block/state';
import Layout from 'common/layout';
import PageLoader from "common/components/modules/page-loading";

class SimpleMixed extends Component {
   static propTypes = {
      authUser: PropTypes.object,
      isSiteInited: PropTypes.bool.isRequired,
      component: PropTypes.any,
      init: PropTypes.func.isRequired,
      pushEnableStatus: PropTypes.string,
      isLouisianaBlocked: PropTypes.bool,
      siteInfo: PropTypes.object,
      isFetchingLogout: PropTypes.bool,
      needToInit: PropTypes.bool,
   };

   componentDidMount() {
      const {
         init,
         isSiteInited,
         needToInit,
      } = this.props;
      if(needToInit && !isSiteInited){
         init();
      }
   }

   render() {
      const {
         component: ChildComponent,
         isSiteInited,
         isLouisianaBlocked,
         siteInfo,
         isFetchingLogout,
         needToInit,
         ...rest
      } = this.props;
      if(isLouisianaBlocked){
         return (
            <StateBlock
               state='Louisiana'
               logo={ siteInfo.site_logo }
            />
         )
      }
      if(!needToInit){
         return (
            <Layout>
               <div className='w-full'>
                  <Route
                     { ...rest }
                     render={ (matchProps) => {
                        return <ChildComponent { ...matchProps } />;
                     } }
                  />
               </div>
            </Layout>
         );
      }
      if(!isSiteInited || isFetchingLogout){
         return (
            <PageLoader withLoader loaderColor='primary' />
         )
      }
      if(isSiteInited) {
         return (
            <Layout>
               <div className='w-full'>
                  <Route
                     { ...rest }
                     render={ (matchProps) => {
                        return <ChildComponent { ...matchProps } />;
                     } }
                  />
               </div>
            </Layout>
         );
      }
      return null
   }
}

const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      siteInfo: siteInfoSelector(state),
      isSiteInited: isSiteInitedSelector(state),
      isLouisianaBlocked: isLouisianaBlockedSelector(state),
      isFetchingLogout: isFetchingLogoutSelector(state),
   };
}

const mapDispatchToProps = (dispatch) => ({
   init: () => {
      dispatch(siteDetailsInitOperation())
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMixed);
