import * as types from './types';

import createReducer from '../../utils/createReducer';

import initialState from './initialState';
import SyncPageScoll from 'common/utils/pageScrollSync';
// import DateFormatter from 'common/utils/DateFormatter';

const reducersMap = {
   [types.OPEN_CHAT_NOTIFICATION_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         showNavigationList: payload,
      };
   },
   [types.CHAT_INIT_REQUEST]: (state, action) => {
      return {
         ...state,
         isFetchingInit: true,
      };
   },
   [types.CHAT_INIT_COMPLETED]: (state, action) => {
      const { payload } = action;
      let newState = {}
      if(payload.settings) {
         newState = {
            mutedInfo: {
               isMuted: payload.settings.muted_since,
               muted_since: payload.settings.muted_since,
               muted_period_in_seconds: payload.settings.muted_period_in_seconds,
            },
         }
      }
      return {
         ...state,
         isFetchingInit: false,
         inited: true,
         ...payload,
         privateChat: {
            ...state.privateChat,
            ...payload.privateChat,
         },
         ...newState,
      };
   },
   [types.CHAT_INIT_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingInit: false,
      };
   },

   [types.FETCH_MESSAGES_REQUEST]: (state, action) => {
      const {
         payload: { type },
      } = action
      if(state[`${ type }Messages`] && state[`${ type }Messages`].inited) {
         return {
            ...state,
            isFetchingMessage: false,
         };
      } else {
         return {
            ...state,
            isFetchingMessage: true,
         };
      }
   },
   [types.FETCH_MESSAGES_COMPLETED]: (state, action) => {
      const { payload: {
         data, id, userUUid, type, conversation,
      } } = action;
      let newState = {
         ...state,
         activeMessageTab: {
            type: type,
            id,
            userUUid,
            conversation,
         },
      }
      if(state[`${ type }Messages`].inited) {
         return {
            ...state,
            ...newState,
         };
      } else {
         newState = {
            ...newState,
            isFetchingMessage: false,
            [`${ type }Messages`]: {
               data: data.data,
               last_page: data.last_page || 1,
               current_page: data.current_page || 1,
               inited: true,
               isEmpty: data.data.length === 0,
            },
         }
      }

      return {
         ...state,
         ...newState,
      };
   },
   [types.FETCH_MESSAGES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingMessage: false,
      };
   },
   [types.SET_ACTIVE_TAB]: (state, action) => {
      return {
         ...state,
         activeMessageTab: { ...action.payload },
      };
   },


   [types.FETCH_NEXT_MESSAGES_REQUEST]: (state, action) => {
      return {
         ...state,
         isNextFetchingMessage: true,
      };
   },
   [types.FETCH_NEXT_MESSAGES_COMPLETED]: (state, action) => {
      const { payload } = action;
      const { type } = state.activeMessageTab
      return {
         ...state,
         isNextFetchingMessage: false,
         [`${ type }Messages`]: {
            last_page: payload.last_page,
            current_page: payload.current_page,
            data: [...state[`${ type }Messages`].data, ...payload.data],
         },
      };
   },
   [types.FETCH_NEXT_MESSAGES_FAILED]: (state, action) => {
      return {
         ...state,
         isNextFetchingMessage: false,
      };
   },

   [types.FETCH_MESSAGE_LIKED_MEMBERS_REQUEST]: (state, action) => {
      let newData = {}
      if(!!action.payload) {
         newData = {
            fetchingScroll: false,
         }
      } else {
         newData = {
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
            data: [],
         },
      };
   },
   [types.FETCH_MESSAGE_LIKED_MEMBERS_COMPLETED]: (state, action) => {
      const {
         data,
         isPagination,
      } = action.payload

      let newData = {
         ...data,
      }
      if(isPagination) {
         newData = {
            ...newData,
            fetchingScroll: false,
         }
      } else {
         newData = {
            ...newData,
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
         },
      };
   },
   [types.FETCH_MESSAGE_LIKED_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         likedMemberByMessage: {
            fetching: false,
            fetchingScroll: false,
            data: [],
         },
      };
   },
   [types.SET_ONLINE_USERS_ACTIONS]: (state, action) => {
      const {
         data, action: memberAction,
      } = action.payload
      let users = [...state.onlineUsers]
      switch (memberAction) {
         case 'push':
            users = [...users, { ...data }]
            break;
         case 'pop':
            users = [...users].filter(item => item.uuid !== data.uuid)
            break;

         default:
            users = [...data]
            break;
      }
      return {
         ...state,
         onlineUsers: users,
      };
   },
   [types.SET_SOCKET_CONNECTED_ACTION]: (state, action) => {

      return {
         ...state,
         isSocketConnected: action.payload,
      }
   },
   [types.SET_MESSAGE_LIKE_ACTIONS]: (state, action) => {
      const { payload: {
         id,
         isMyLiked,
         isLiked,
         type,
      } } = action
      const newMessage = [...state[`${ type }Messages`].data].map(message => {
         let { ...elm } = message
         if(elm.id === id) {
            let count = elm.likes_count_from_other_users || 0
            if(isLiked) {
               count = ++count
            }
            if(!isLiked && count > 0) {
               count = --count
            }
            elm = {
               ...elm,
               likes_count_from_other_users: count,
            }
            if(isMyLiked) {
               elm = {
                  ...elm,
                  is_liked_by_user: isLiked,
               }
            }
         }
         return elm
      })
      return {
         ...state,
         [`${ type }Messages`]: {
            ...state[`${ type }Messages`],
            data: newMessage,
         },
      }
   },

   [types.DELETE_MESSAGE]: (state, action) => {
      const { payload: {
         id,
         type,
         key,
         lastMessage,
      } } = action

      let messageIndex = null
      let newMessages = []
      if(!!state[`${ type }Messages`]) {
         messageIndex = [...state[`${ type }Messages`].data].findIndex(message => {
            if(id) {
               return message.id === id
            } else {
               return message[key]
            }
         })
         if(id) {
            newMessages = [...state[`${ type }Messages`].data].filter(message => message.id !== id)
         } else {
            newMessages = [...state[`${ type }Messages`].data].filter(message => !message[key])
         }

         if(!!newMessages?.length) {
            newMessages = newMessages.map(item => {
               if(!!item.parent && item?.parent?.id === id) {
                  return {
                     ...item,
                     parent: null,
                     parentMessage: null,
                  }
               }
               return item
            })
         }
      }
      let emptyState = {}
      if(state[`${ type }Messages`] && !state[`${ type }Messages`].isEmpty && newMessages.length === 0) {
         emptyState = { isEmpty: true }
      }
      let conversation = {}
      if(messageIndex === 0) {
         let last_message = {}
         if(newMessages[0]) {
            last_message = {
               text: newMessages[0].text,
               user_uuid: newMessages[0].user.uuid,
               created_at: newMessages[0].created_at,
               username: newMessages[0].user.username ||  newMessages[0].user.screen_name,
            }

         }

         if(!newMessages[0]?.text && !!newMessages?.length) {
            if('photo_unlock' === newMessages[0]?.type){
               let photos_count =  newMessages[0]?.unlock_details?.photos_count
               last_message.text = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
            }
            if('video_unlock' === newMessages[0]?.type){
               last_message.text = 'New video!'
            }
            if('voice_message' === newMessages[0]?.type){
               last_message.text = 'Voice message!'
            }
            if('multiple_attachment' === newMessages[0]?.type){
               last_message.text = 'Multiple attachments!'
            }
         }

         if(!newMessages?.length && !!lastMessage?.text) {
            last_message = lastMessage
         }

         switch (type) {
            case 'public':
               conversation = {
                  groupChat: {
                     ...state.groupChat,
                     last_message: last_message,

                  },
               }
               break;
            case 'private':
               conversation = {
                  privateChat: {
                     ...state.privateChat,
                     last_message: last_message,
                  },
               }
               break;

            default:
               break;
         }

      }
      return {
         ...state,
         [`${ type }Messages`]: {
            ...state[`${ type }Messages`],
            data: newMessages,
            ...emptyState,
         },
         ...conversation,
      }
   },
   [types.ADD_NEW_MESSAGE]: (state, action) => {
      const { payload: {
         message,
         type,
         isMyMessage,
      } } = action
      let messageObj = {
         data: [],
      }
      if(state[`${ type }Messages`]) {
         messageObj = state[`${ type }Messages`]
      }
      let newMessages = [{ ...message }, ...messageObj.data]
      let emptyState = {}
      if(!!messageObj.isEmpty && newMessages.length > 0) {
         emptyState = { isEmpty: false }
      }
      let conversation = {}

      let lastMessageText =  message.text;
      if(!message.text) {
         if('photo_unlock' === message.type){
            let photos_count =  message.unlock_details?.photos_count
            lastMessageText = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
         }
         if('video_unlock' === message.type){
            lastMessageText = 'New video!'
         }
         if('voice_message' === message.type){
            lastMessageText = 'Voice message!'
         }
         if('multiple_attachment' === message.type){
            lastMessageText = 'Multiple attachments!'
         }
      }

      if(!!newMessages?.length && !!message?.unlock_details && (!message?.unlock_details?.unlock_price || !!message?.unlock_details?.is_unlocked_for_view)) {
         let alreadyExistedItems = []

         if(!['multiple_attachment', 'promo'].includes(message?.type) && message?.unlock_details?.type !== 'multiple_attachment') {
            alreadyExistedItems = newMessages.filter(it => {
               if(!!it.unlock_details && it.unlock_details.resource_id === message?.unlock_details?.resource_id && message?.unlock_details?.resource_type === it?.unlock_details?.resource_type && it?.unlock_details?.unlock_price) {
                  return it
               }
               return null
            }).map(it => it.id)
         }

         if(!!alreadyExistedItems?.length){
            newMessages = newMessages.map(it => {

               if(alreadyExistedItems?.includes(it.id)){
                  return {
                     ...it,
                     unlock_details: { ...it?.unlock_details, ...message?.unlock_details },
                     unlockDetails: { ...it?.unlockDetails, ...message?.unlockDetails },
                  }
               }

               return it
            })
         }
      }

      switch (type) {
         case 'public':
            let unreadCount = state.groupChat.unread_messages_count || 0
            let unreadparticipantCount = state.groupChat.unread_messages_count_of_second_participant || 0
            conversation = {
               groupChat: {
                  ...state.groupChat,
                  last_message: {
                     user_uuid: message.user.uuid,
                     text: lastMessageText,
                     username: message.user.username || message.user.screen_name,

                  },
                  unread_messages_count: isMyMessage ? unreadCount : unreadCount + 1,
                  unread_messages_count_of_second_participant: !isMyMessage ? unreadparticipantCount : unreadparticipantCount + 1,

               },
            }
            break;
         case 'private':
            conversation = {
               privateChat: {
                  ...state.privateChat,

                  unread_messages_count: isMyMessage ? state.privateChat.unread_messages_count : state.privateChat.unread_messages_count + 1,
                  unread_messages_count_of_second_participant: !isMyMessage ? state.privateChat.unread_messages_count_of_second_participant : state.privateChat.unread_messages_count_of_second_participant + 1,
                  last_message: {
                     user_uuid: message?.user?.uuid,
                     text: lastMessageText,
                  },
               },
            }
            break;

         default:
            break;
      }

      return {
         ...state,
         [`${ type }Messages`]: {
            ...state[`${ type }Messages`],
            data: newMessages,
            ...emptyState,
         },
         ...conversation,
      }
   },
   [types.UPDATE_MESSAGE_DATA]: (state, action) => {
      const { payload: {
         data,
         type,
         id,
      } } = action

      const newMessages = [...[...state[`${ type }Messages`]?.data]].map(message => {
         let { ...elm } = message

         if(elm.id === id) {
            elm = { ...elm, ...data }
         }

         if(!!elm?.parent && elm?.parent?.id === id) {
            if(!!data.unlock_details) {
               elm.parent.unlock_details = data.unlock_details
            }

            if(!!data?.text) {
               elm.parent.text = data.text
            }

            if(!!data?.duration_in_minutes) {
               elm.parent.duration_in_minutes = data?.duration_in_minutes
               elm.parent.show_duration_countdown_to_member = data?.show_duration_countdown_to_member
               elm.parent.unsend_date = data?.unsend_date
            }
            if(data?.duration_in_minutes === 0) {
               elm = {
                  ...elm,
                  parentDurationInMinutes: data?.duration_in_minutes,
                  parentShowDurationCountdownToMember: data?.show_duration_countdown_to_member,
                  parentUnsendDate: data?.unsend_date,
                  parent: {
                     ...elm?.parent,
                     duration_in_minutes: data?.duration_in_minutes,
                     show_duration_countdown_to_member: data?.show_duration_countdown_to_member,
                     unsend_date: data?.unsend_date,
                  },

               }
            }
         }

         if(
            message?.id !== id
            && message?.unlock_details
            && data?.unlock_details
            && message?.unlock_details?.resource_id === data?.unlock_details?.resource_id
            && message?.unlock_details?.resource_type === data?.unlock_details?.resource_type
            && !!data?.unlock_details?.cover
            && data?.unlock_details?.is_unlocked
         ) {
            elm = {
               ...elm,
               unlock_details: {
                  ...elm.unlock_details,
                  cover: data?.unlock_details?.cover,
                  is_unlocked_for_view: data?.unlock_details?.is_unlocked,
                  cover_thumb: data?.unlock_details?.cover_thumb,
               },
            }
         }
         if(
            message?.id !== id
            && message?.unlock_details
            && data?.unlock_details
            && !!message?.unlock_details?.attachments
            && (!!message?.unlock_details?.attachments.find(it => it?.resource_id === data?.unlock_details?.resource_id) || message?.unlock_details?.attachments.find(it =>  typeof it?.resource_id === 'string' &&  it?.resources?.[0] === data?.unlock_details?.resources?.[0]))
            && !!data?.unlock_details?.cover
            && data?.unlock_details?.is_unlocked
         ) {
            elm = {
               ...elm,
               unlock_details: {
                  ...elm.unlock_details,
                  attachments: elm.unlock_details.attachments.map(ct => {
                     if((ct.resource_id === data?.unlock_details?.resource_id) || (typeof data?.unlock_details?.resource_id === 'string' &&  ct?.resources?.[0] === data?.unlock_details?.resources?.[0])) {
                        return {
                           ...ct,
                           cover: data?.unlock_details?.cover,
                           cover_thumb: data?.unlock_details?.cover_thumb,
                        }
                     } else {
                        return ct
                     }
                  }),
                  is_unlocked_for_view: data?.unlock_details?.is_unlocked && elm.unlock_details.attachments.length === 1,
               },
            }
         }


         return elm
      })

      let messageIndex = [...state[`${ type }Messages`].data].findIndex(message => message.id === id)
      let updatedMessage = [...state[`${ type }Messages`].data].find(message => message.id === id)
      let conversation = {}
      if(messageIndex === 0) {
         let last_message = {}
         if(newMessages[0]) {
            last_message = {
               text: newMessages[0].text,
               user_uuid: newMessages[0].user.uuid,
               created_at: newMessages[0].created_at,
               username: newMessages[0].user.username ||  newMessages[0].user.screen_name,
            }

         }

         if(!newMessages[0].text) {
            if('photo_unlock' === updatedMessage.type){
               let photos_count =  updatedMessage.unlock_details?.photos_count
               last_message.text = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
            }
            if('video_unlock' === updatedMessage.type){
               last_message.text = 'New video!'
            }
            if('voice_message' === updatedMessage.type){
               last_message.text = 'Voice message!'
            }
            if('multiple_attachment' === updatedMessage.type){
               last_message.text = 'Multiple attachments!'
            }
         }

         switch (type) {
            case 'public':
               conversation = {
                  groupChat: {
                     ...state.groupChat,
                     last_message: last_message,

                  },
               }
               break;
            case 'private':
               conversation = {
                  privateChat: {
                     ...state.privateChat,
                     last_message: last_message,
                  },
               }
               break;

            default:
               break;
         }

      }

      return {
         ...state,
         [`${ type }Messages`]: {
            ...state[`${ type }Messages`],
            data: newMessages,
         },
         ...conversation,
      }
   },
   [types.UPDATE_CONVERSTION_DATA]: (state, action) => {
      const { payload: {
         data,
         id,
      } } = action
      let newState = {}
      switch (id) {
         case state.privateChat.chat_room_id:
            newState = {
               privateChat: {
                  ...state.privateChat,
                  ...data,
               },
            }
            break;
         case state.groupChat.chat_room_id:
            newState = {
               groupChat: {
                  ...state.groupChat,
                  ...data,
               },
            }
            break;

         default:
            break;
      }
      return {
         ...state,
         ...newState,
      }
   },
   [types.UPDATE_PRIVATE_CONVERSATION_ID]: (state, action) => {
      const id  = action.payload
      let newState = {}
      if('private' === state.activeMessageTab.type){
         newState = {
            activeMessageTab: {
               ...state.activeMessageTab,
               id,
            },
         }
      }
      return {
         ...state,
         ...newState,
         privateChat: {
            ...state.privateChat,
            chat_room_id: id,
         },
      }

   },
   [types.SET_MUTED_MEMBER]: (state, action) => {
      const { payload }  = action

      return {
         ...state,
         mutedInfo: {
            ...state.mutedInfo,
            ...payload,
         },
      }

   },
   [types.SET_CHAT_PANEL]: (state, action) => {
      const { payload }  = action
      let style = {}
      if(payload.isMobile) {
         setTimeout(() => {
            SyncPageScoll.syncScroll(payload.isOpen)
         }, 100);
      }
      if(payload.animationClass) {
         style = { ...payload.animationClass }
      } else {
         style = {
            animation: payload.isOpen ? 'slide-in-right-chat 0.6s forwards' : 'slide-out-right-chat 0.6s forwards',
            boxShadow: payload.isOpen ? '-8px 0px 12px rgba(0, 0, 0, 0.25)' : 'none',
         }

      }
      return {
         ...state,
         chatPanel: {
            ...state.chatPanel,
            ...payload,
            animationClass: style,
         },
      }

   },

   [types.CLEAR_CHAT_INFO]: (state, action) => {
      return {
         ...state,
         ...initialState,
         isSocketConnected: state.isSocketConnected,
      }
   },
   [types.SET_CHAT_SETTINGS]: (state, action) => {
      let newState = {}
      let { data } = action.payload
      if((('chat_private_is_active' in data && !data.chat_private_is_active)
      && ('is_group_chat_active' in data && !data.is_group_chat_active))) {
         newState = {
            chatPanel: {
               ...state.chatPanel,
               animationClass: {},
               closeChatMessages: false,
               isOpen: false,
            },
         }
      }
      return {
         ...state,
         ...newState,
         settings: {
            ...state.settings,
            ...data,
         },
         hiddeChatIcon: !data.chat_private_is_active && !data.is_group_chat_active,
      }
   },
   [types.SET_CHAT_ICON_STATE]: (state, action) => {
      return {
         ...state,
         hiddeChatIcon: action.payload,
      }
   },
   [types.OPEN_CLOSED_CONVERSATION_COMPLETED]: (state) => {
      return {
         ...state,
         privateChat: {
            ...state.privateChat,
            type: 'open',
         },
      }
   },
   [types.SHOW_LOADER_DURING_THE_TRANSLATION]: (state, action) => {
      return {
         ...state,
         isFetchingMessage: action.payload,
      };
   },
};

export default createReducer(initialState)(reducersMap);
