import React from 'react';
import PropTypes from 'prop-types';
import Link from './link';
import PrimaryButton from 'common/components/elements/buttons/primary';
import Separator from './separator';
import Group from './group';
import Subgroup from './subgroup';
import membersiteLogo from 'assets/images/membersite-logo.png'
import { Provider } from './context';
import cx from 'classnames';
import './style.scss';
import TruncateText from 'common/components/elements/truncate-text';
import Button from 'common/components/elements/buttons/primary';
import Avatar from '../avatar';
import mmsLogo from 'assets/images/mms-logo.svg'


class Navigation extends React.Component {

   constructor(props) {
      super(props);
      let activeGroupTitle = null;
      this.recursiveMap(this.props.children, (child) => {
         if(child.type.displayName === 'Subgroup') {
            const { title, children } = child.props;
            React.Children.map(children, innerChild => {
               const { active } = innerChild.props;
               if(active) {
                  activeGroupTitle = title;
               }
            });
         }
      });
      this.state = {
         activeGroupTitle,
      };
   }

   recursiveMap = (children, fn) =>  {
      return React.Children.map(children, child => {
         if(!React.isValidElement(child)) {
            return child;
         }
         if(child.type.displayName !== 'Subgroup' && child.props.children) {
            child = React.cloneElement(child, {
               children: this.recursiveMap(child.props.children, fn),
            });
         }
         return fn(child);
      });
   }

   handleChangeActiveGroup = (title) => {
      const { activeGroupTitle } = this.state;
      if(title === activeGroupTitle) {
         this.setState({
            activeGroupTitle: null,
         });
      } else {
         this.setState({
            activeGroupTitle: title,
         });
      }
   }

   render() {
      const {
         onClickMyAccount = () => console.log('onClickMyAccount'),
         onClose = () => console.log('onClose'),
         children,
         type = 'mobile',
         user,
         isAdmin,
         // onClickPreview,
         onShareLink,
         onClickWebsiteLogo,
         siteInfo,
         onClickSiteUrl,
         isNextApp,
      } = this.props;
      const { activeGroupTitle } = this.state;
      const width = type === 'mobile' ? '375px' : '56px';
      return (
         <Provider
            value={ {
               type,
               activeGroupTitle,
               onChangeActiveGroup: this.handleChangeActiveGroup,
            } }
         >
            <nav
               className='bg-panel dark:bg-panel-dark border-r border-divider dark:border-divider-dark h-full flex flex-col navigation-element overflow-hidden'
               style={ {
                  maxWidth: width,
                  minWidth: '56px',
               } }
               onClick={ (e) => e.stopPropagation() }
               role='presentation'
            >
               {
                  type === 'mobile' &&
                     <div className={ `${ isAdmin  ? `h-[116px] relative top-[0px]` : `` }  ` }>
                        <>
                           <div className={ `w-full ${ isAdmin ? `pt-2 pl-4` : `p-4 pl-2 h-18 ` } ` }>
                              {
                                 isAdmin &&
                                    <div className='flex items-center justify-between'>
                                       <img
                                          alt=''
                                          src={ mmsLogo }
                                          className='h-[32px] w-[210px] object-contain object-center hover:cursor-pointer'
                                          role='presentation'
                                          onClick={ (e) => {
                                             e.stopPropagation()
                                             onClickWebsiteLogo()
                                          } } />
                                       <div className='h-10 w-10 my-1'>
                                          <PrimaryButton
                                             iconName='close'
                                             padding='none'
                                             backgroundColor='transparent'
                                             fontIconColor='secondary'
                                             onClick={ onClose }
                                             classNames='h-full'
                                          />
                                       </div>
                                    </div>
                              }
                              {
                                 !isAdmin &&
                                    <div className='h-10 w-10'>
                                       <PrimaryButton
                                          iconName='close'
                                          padding='none'
                                          backgroundColor='transparent'
                                          fontIconColor='secondary'
                                          onClick={ onClose }
                                          classNames='h-full'
                                       />
                                    </div>
                              }
                           </div>
                           {
                              user && (
                                 <div>
                                    {
                                       !isAdmin && (
                                          <>
                                             <Avatar
                                                avatarSrc={ user.avatar }
                                                username={ user.screen_name }
                                                size='50px'
                                                className='m-auto'
                                                imageClassName='m-auto'
                                                isNextApp={ isNextApp }
                                             />
                                             <TruncateText
                                                className='text-center mt-2 m-auto justify-center'
                                                text={ user.screen_name }
                                                textClass='text-xl text-major font-bold leading-tight dark:text-major-dark whitespace-pre notranslate'
                                                textSize='20px'
                                                fontWeight='700'
                                                width='90%'
                                             />
                                             <Button
                                                backgroundColor='transparent'
                                                iconName='account'
                                                textSize='base'
                                                text='My account'
                                                iconPosition='left'
                                                padding='none'
                                                onClick={ onClickMyAccount }
                                                primaryColor
                                                classNames='!w-fit mx-auto mt-3'
                                             />
                                          </>
                                       )
                                    }
                                    {
                                       isAdmin && (
                                          <div className='mx-4 pt-4'>
                                             <div className='flex **mt-2 gap-2 items-center'>
                                                {/* <span className='text-sm text-secondary dark:text-secondary-dark self-center'>{ siteInfo?.site_url }</span> */}
                                                <PrimaryButton
                                                   text={ siteInfo?.site_url }
                                                   classNames='h-5 !w-[fit-content] hover:underline hover:cursor-pointer'
                                                   padding='none'
                                                   // iconName='share'
                                                   // iconPosition='left'
                                                   // fontIconSize='extraLarge'
                                                   // fontIconColor='secondary'
                                                   // darkIconColor='secondary-dark'
                                                   backgroundColor='transparent'
                                                   textColor='secondary dark:text-secondary-dark '
                                                   borderRadius='large'
                                                   fontWeight='normal'
                                                   textSize='small'
                                                   waveColor='rgba(33, 33, 33, 0.8)'
                                                   onClick={ onClickSiteUrl }
                                                   textMarginX='0'
                                                   lineHeight='leading-5'
                                                />
                                                <PrimaryButton
                                                   text='Share'
                                                   classNames='h-9 pl-3 pr-2 !w-[fit-content]'
                                                   padding='none'
                                                   iconName='share'
                                                   iconPosition='left'
                                                   fontIconSize='extraLarge'
                                                   fontIconColor='secondary'
                                                   darkIconColor='secondary-dark'
                                                   backgroundColor='transparent'
                                                   textColor='secondary dark:text-secondary-dark '
                                                   borderRadius='large'
                                                   textSize='small'
                                                   waveColor='rgba(33, 33, 33, 0.8)'
                                                   onClick={ onShareLink }
                                                />
                                                {/* <div className='h-9'>
                                                   <PrimaryButton
                                                      text='View my site'
                                                      classNames='h-full px-3.5'
                                                      padding='none'
                                                      iconName='views'
                                                      iconPosition='left'
                                                      fontIconSize='extraLarge'
                                                      fontIconColor='secondary'
                                                      darkIconColor='secondary-dark'
                                                      backgroundColor='transparent'
                                                      textColor='secondary dark:text-secondary-dark '
                                                      borderRadius='large'
                                                      textSize='base'
                                                      waveColor='rgba(33, 33, 33, 0.8)'
                                                      onClick={ onClickPreview }
                                                   />
                                                </div>
                                                <div className='h-9 ml-2'>
                                                   <PrimaryButton
                                                      text='Share'
                                                      classNames='h-full pl-3 pr-2'
                                                      padding='none'
                                                      iconName='share'
                                                      iconPosition='left'
                                                      fontIconSize='extraLarge'
                                                      fontIconColor='secondary'
                                                      darkIconColor='secondary-dark'
                                                      backgroundColor='transparent'
                                                      textColor='secondary dark:text-secondary-dark '
                                                      borderRadius='large'
                                                      textSize='base'
                                                      waveColor='rgba(33, 33, 33, 0.8)'
                                                      onClick={ onShareLink }
                                                   />
                                                </div> */}
                                             </div>
                                          </div>
                                       )
                                    }
                                    <div className='w-full px-4 mt-2 mb-2'>
                                       <div className='border-t dark:border-divider-dark border-divider w-full' />
                                    </div>
                                 </div>
                              )
                           }
                        </>
                     </div>
               }
               {
                  type === 'desktop' && (
                     <div className='flex justify-center items-center mt-2 w-full min-w-full h-[48px] min-h-[48px]'>
                        <div
                           className='cursor-pointer'
                           role='presentation'
                           onClick={ (e) => {
                              e.stopPropagation()
                              onClickWebsiteLogo()
                           } }
                        >
                           <img src={ membersiteLogo } alt='logo' className='w-[30px] h-[30px]' />
                        </div>
                     </div>
                  )
               }
               <div
                  className={ cx({
                     'h-full flex flex-col overflow-y-auto ams-custom-scrool': true,
                     'pb-7': (type === 'desktop'),
                  }) }
               >
                  { children }
               </div>
            </nav>
         </Provider>
      );
   }
}

Navigation.Separator = Separator;
Navigation.Link = Link;
Navigation.Group = Group;
Navigation.Subgroup = Subgroup;

Navigation.propTypes = {
   children: PropTypes.any,
   type: PropTypes.oneOf([
      'mobile',
      'desktop',
   ]),
   onClose: PropTypes.func,
   onClickMyAccount: PropTypes.func,
   user: PropTypes.any,
   isAdmin: PropTypes.bool,
   // onClickPreview: PropTypes.func,
   onShareLink: PropTypes.func,
   onClickWebsiteLogo: PropTypes.func,
   siteInfo: PropTypes.object,
   onClickSiteUrl: PropTypes.func,
   isNextApp: PropTypes.bool,
};


export default Navigation;
