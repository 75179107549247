import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState, { POLLS_MODAL_DEFAULT_STATE } from './initialState';
import { isEmpty } from "lodash";

let reducersMap = {
   [types.CHECK_AND_STORE_POLL]: (state, action) => {
      const pollData = action.payload;

      let newState = {}
      if(pollData) {
         newState.pollData = pollData
      }

      return {
         ...state,
         ...newState,
      }
   },
   [types.OPEN_ACTIVE_POLL_MODAL_START]: (state, action) => {

      return {
         ...state,
         isFetchingPollData: true,
         pollModal: {
            ...state.pollModal,
            isOpen: true,
         },
      }
   },
   [types.OPEN_ACTIVE_POLL_MODAL_COMPLETED]: (state, action) => {
      const { pollData } = action.payload;
      if(!pollData || isEmpty(pollData)) {
         return {
            ...state,
            pollModal: {
               ...state.pollModal,
               isOpen: false,
            },
            pollData: null,
         }
      }

      let modalType = 'vote'
      const { status } = pollData

      if('ended' === status) {
         modalType = 'ended_poll'
      } else if(Boolean(pollData.user_voted_option_id)) {
         modalType = 'vote_submitted'
      } else {
         modalType = 'vote'
      }



      return {
         ...state,
         isFetchingPollData: false,
         pollData,
         pollModal: {
            ...state.pollModal,
            isOpen: true,
            type: modalType,
         },
      }
   },
   [types.SET_POLL_MODAL_TYPE]: (state, action) => {
      const { type } = action.payload

      return {
         ...state,
         pollModal: {
            ...state.pollModal,
            type,
         },
      }
   },
   [types.CLOSE_POLL_MODAL]: (state) => ({
      ...state,
      pollModal: { ...POLLS_MODAL_DEFAULT_STATE },
   }),
   [types.SET_IS_FETCHING_SUBMIT_VOTE]: (state, action) => ({
      ...state,
      isFetchingSubmitVote: action.payload,
   }),
   [types.SUBMIT_VOTE_COMPLETED]: (state, action) => {
      const { updatedPoll } = action.payload

      return {
         ...state,
         isFetchingSubmitVote: false,
         pollData: updatedPoll,
         pollModal: {
            ...state.pollModal,
            type: 'vote_submitted',
         },
      }
   },
   [types.VIEW_POLL_COMPLETED]: (state) => {

      return {
         ...state,
         pollData: {
            ...state.pollData,
            member_viewed: true,
         },
      }
   },
   [types.OPEN_CLOSE_POLL_CONFIRM_DIALOG]: (state, action) => {
      const { type } = action.payload

      return {
         ...state,
         pollConfirmDialog: {
            isOpen: Boolean(type),
            type,
         },
      }
   },
}

export default createReducer(initialState)(reducersMap);
