import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Tooltip } from 'react-tooltip';
import ReactDOM, { createPortal } from 'react-dom';

import { copyText } from 'common/utils/utils.js';
import toast from 'common/utils/toast';

import Icon from 'common/components/elements/icons';
import cx from 'classnames'

const TruncateText = ({
   text,
   className,
   textClass,
   textSize,
   fontWeight,
   width,
   href,
   isLink,
   tooltipIdForHtmlContent,
   target,
   notShowTooltip,
   toastText,
   alwaysShowTooltip,
   tooltipId,
   onClick,
   highlitedText,
   isTextReactNode,
   hideCopyButton,
   showTootltipText,
   tooltipText,
   innerTextAsHTML,
   customText,
   customIcon,
   customIconClassName,
   onClickCustomIcon,
}) => {
   const ref = useRef(null);
   const [showTootltip, setShowTooltip] = useState(false);
   useEffect(() => {
      window.addEventListener('resize', onMouseOver)
      return () => {
         window.removeEventListener('resize', onMouseOver)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      onMouseOver();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [text, textSize])

   const onMouseOver = e => {
      if(notShowTooltip) {
         return;
      }
      if(alwaysShowTooltip) {
         setShowTooltip(true)
         return
      }
      if(ref.current){
         const spanElement = document.createElement('span');
         spanElement.classList.add('absolute', 'whitespace-nowrap', 'invisible', 'top-0', 'left-0', 'h-full', 'span_to_calculate')
         spanElement.style.fontSize = textSize;
         spanElement.style.fontWeight = fontWeight;
         if(isTextReactNode) {
            const spansList = ref.current.querySelectorAll('.span_to_calculate')
            if(spansList.length > 0) {
               spansList.forEach(el => {
                  el.remove()
               })
            }
            ReactDOM.render(text, spanElement, () => {
               if(spanElement && ref.current) {
                  if(spanElement.offsetWidth > ref.current.offsetWidth){
                     setShowTooltip(true)
                  } else {
                     setShowTooltip(false)
                  }
                  if(ref.current.contains(spanElement)) {
                     ref.current.removeChild(spanElement);
                  }
               }
            })
            ref.current.appendChild(spanElement);
         } else {
            spanElement.innerHTML = text;
            ref.current.appendChild(spanElement);
            if(spanElement.offsetWidth > ref.current.offsetWidth){
               setShowTooltip(true)
            } else {
               setShowTooltip(false)
            }
            ref.current.removeChild(spanElement);
         }
      }
   }

   const onClickCopy = (e) => {
      copyText(text);
      if(toastText){
         toast.success(toastText);
         return;
      }
      let cutText = text;
      if(text.length > 25) {
         cutText = `${ text.slice(0, 25) }...`;
      }
      toast.success(`${ cutText } has been copied`);
   }

   const onClickIcon = (e) => {
      onClickCustomIcon(e)
   }

   if(tooltipIdForHtmlContent){
      return (
         <>
            <TruncateTextComponent
               showTootltip={ showTootltip }
               width={ width }
               text={ text }
               className={ className }
               contentref={ ref }
               isLink={ isLink }
               textClass={ textClass }
               href={ href }
               id={ `ams-html-tooltip-${ tooltipIdForHtmlContent }` }
               hasChildren
               target={ target }
               notShowTooltip={ notShowTooltip }
               onClick={ onClick }
               tooltipText={ tooltipText }
               innerTextAsHTML={ innerTextAsHTML }
            />
            {
               createPortal(
                  <Tooltip
                     place='top'
                     className={ cx({
                        'ams-black-tooltips': true,
                     }) }
                     id={ showTootltip  ? `ams-html-tooltip-${ tooltipIdForHtmlContent }` : null }
                     clickable
                  >
                     <span
                        onClick={ e => {
                           e.preventDefault()
                           e.stopPropagation()
                        } }
                        role='presentation'
                     >
                        {
                           !customText && text
                        }&nbsp;
                        {
                           !!customText && customText
                        }&nbsp;
                        {
                           !hideCopyButton && !customIcon && (
                              <Icon
                                 name='copy'
                                 size='sm'
                                 className='cursor-pointer opacity-1 dark:text-major'
                                 color='major-dark'
                                 darkColor='major'
                                 onClick={ onClickCopy }
                              />
                           )
                        }
                        {
                           customIcon &&
                              <Icon
                                 name={ customIcon }
                                 size='sm'
                                 className={ `cursor-pointer opacity-1 dark:text-major ${ !!customIconClassName ? customIconClassName : '' }` }
                                 color='major-dark'
                                 darkColor='major'
                                 onClick={ onClickIcon }
                              />
                        }
                     </span>
                  </Tooltip>
                  ,
                  document.getElementById('tooltips')
               )
            }
         </>
      )
   }
   return (
      <TruncateTextComponent
         showTootltip={ showTootltip }
         width={ width }
         text={ text }
         className={ className }
         contentref={ ref }
         isLink={ isLink }
         textClass={ textClass }
         href={ href }
         target={ target }
         notShowTooltip={ notShowTooltip }
         id={ tooltipId }
         onClick={ onClick }
         highlitedText={ highlitedText }
         showTootltipText={ showTootltipText }
         tooltipText={ tooltipText }
         innerTextAsHTML={ innerTextAsHTML }
      />

   );
};

const TruncateTextComponent = ({
   showTootltip,
   showTootltipText,
   width,
   text,
   className,
   contentref,
   isLink,
   textClass,
   href,
   id,
   hasChildren,
   target,
   notShowTooltip,
   onClick,
   highlitedText,
   tooltipText,
   innerTextAsHTML,
}) => {

   const getParts = () => {
      if(!text) return []
      return text.split(new RegExp(`(${ highlitedText })`, 'gi'));
   }

   return (
      <div
         data-tooltip-content={ !hasChildren && showTootltip && !notShowTooltip ? text : '' }
         data-tooltip-id={ id }
         style={ {
            maxWidth: width,
            // direction: 'rtl',
         } }
         ref={ contentref }
         className={ `${ className } truncate-text-content relative` }>
         {
            innerTextAsHTML
               ?
               (
                  <span role='presentation' onClick={ onClick } className={ `truncate ${ textClass }` } dangerouslySetInnerHTML={ { __html: text } } ></span>
               )
               :
               (
                  <>
                     {
                        !isLink && (
                           !highlitedText ? (
                              <span role='presentation' onClick={ onClick } className={ `truncate ${ textClass }` }>{text} </span>
                           ) : (
                              <span
                                 role='presentation'
                                 onClick={ onClick }
                                 className={ `truncate ${ textClass }` }
                              >
                                 {getParts().map((part, index) => (
                                    part.toLowerCase() === highlitedText.toLowerCase() ? (
                                       <span key={ index } className='_highlighted_text bg-pending'>{part}</span>
                                    ) : (
                                       <span key={ index } className={ textClass }>{part}</span>
                                    )
                                 ))}
                              </span>
                           )
                        )
                     }

                     {
                        isLink && (
                           <a onClick={ onClick } href={ href } className={ `truncate ${ textClass }` } target={ target || '' }> {text} </a>
                        )
                     }
                  </>

               )
         }
      </div>
   )
}


TruncateText.propTypes = {
   text: PropTypes.string,
   className: PropTypes.string,
   textClass: PropTypes.string,
   textSize: PropTypes.string,
   fontWeight: PropTypes.string,
   width: PropTypes.string,
   href: PropTypes.string,
   isLink: PropTypes.bool,
   tooltipIdForHtmlContent: PropTypes.string,
   target: PropTypes.string,
   notShowTooltip: PropTypes.bool,
   // newStyle: PropTypes.bool,
   toastText: PropTypes.string,
   alwaysShowTooltip: PropTypes.bool,
   tooltipId: PropTypes.string,
   onClick: PropTypes.func,
   highlitedText: PropTypes.string,
   showTootltipText: PropTypes.string,
   isTextReactNode: PropTypes.bool,
   hideCopyButton: PropTypes.bool,
   tooltipText: PropTypes.string,
   innerTextAsHTML: PropTypes.bool,
   customText: PropTypes.string,
   customIcon: PropTypes.string,
   customIconClassName: PropTypes.string,
   onClickCustomIcon: PropTypes.func,
};
TruncateTextComponent.propTypes = {
   text: PropTypes.string,
   className: PropTypes.string,
   textClass: PropTypes.string,
   width: PropTypes.string,
   href: PropTypes.string,
   isLink: PropTypes.bool,
   showTootltip: PropTypes.bool,
   hasChildren: PropTypes.bool,
   contentref: PropTypes.any,
   id: PropTypes.string,
   target: PropTypes.string,
   notShowTooltip: PropTypes.bool,
   onClick: PropTypes.func,
   highlitedText: PropTypes.string,
   showTootltipText: PropTypes.string,
   tooltipText: PropTypes.string,
   innerTextAsHTML: PropTypes.bool,
};
TruncateText.defaultProps = {
   textSize: '16px',
   fontWeight: '400',
   position: 'top',
   width: '100%',
   textClass: '',
   className: '',
   target: '',
   notShowTooltip: false,
   alwaysShowTooltip: false,
   isTextReactNode: false,
   hideCopyButton: false,
   tooltipText: '',
};
TruncateTextComponent.defaultProps = {
   id: 'ams-top-tooltip-clickable',
};

export default TruncateText;
