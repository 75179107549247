import moment from 'moment';
import { Duration } from 'luxon';
import momentTimezone from 'moment-timezone';

const backendTimezone = 'UTC';
const userTimeZone = momentTimezone.tz.guess();

class DateFormatter
{
   static toDate(date, full = false) {
      if(date) {
         return  full ? moment(date).format('MMM D, YYYY • h:mm') : moment(date).format('MMM D, YYYY')
      }
   }

   static addDate(durationTime, date){
      if(date){
         const storyRemoveDate = moment(date).add(durationTime, 'days'); // another date
         return storyRemoveDate.format('MMM D, YYYY')
      }
   }
   static storyDate(date, hideAfterMinutes){
      window.mom = moment
      const currentTime = moment();
      let timeSector = null;
      let timeDifference = null;
      const storyRemoveDate = moment(`${ date }+00`).add(hideAfterMinutes, 'M'); // another date
      const duration = moment.duration(storyRemoveDate.diff(currentTime));
      const diffTime = currentTime.diff(moment(`${ date }+00`), 'minutes');
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      timeSector = diffTime === 0 ? 1 : diffTime * 0.25;
      if(hours > 0){
         timeDifference = `${ hours }h ${ minutes }m left`
      } else if(minutes >= 1){
         timeDifference = `${ minutes }m left`
      } else {
         timeDifference = `${ seconds }s left`
      }
      return {
         time: timeDifference,
         sector: timeSector,
      }
   }
   static getSecondsConversion(seconds){
      if(!seconds){
         return '00:00'
      }
      let fromObj = Duration.fromObject({ seconds });
      let result = Duration.fromObject({ seconds }).toFormat("s's'")
      const hours = fromObj.shiftTo('hours').toObject();
      const minutes = fromObj.shiftTo('minutes').toObject()
      if(hours.hours >= 1){
         result = Duration.fromObject({ seconds }).toFormat("h'h' m'm'")
      } else if(minutes.minutes >= 1){
         result = Duration.fromObject({ seconds }).toFormat("m'm' s's'")
      }
      return result
   }

   static convertToUserTz(date, format = 'YYYY-MM-DD HH:mm:ss') {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(date, backendTimezone);
      return result.tz(userTimeZone).format(format);
   }
   static convertToUserTzV2(date, format = 'YYYY-MM-DD HH:mm:ss') {
      return momentTimezone(date).format(format);
   }

   static getUserZoneAbbr() {
      return momentTimezone.tz(userTimeZone).zoneAbbr();
   }

   static getZoonFullName(){
      let zoneAbbr = DateFormatter.getUserZoneAbbr();
      const offset = DateFormatter.getOffsetByUtc(new Date())
      if(zoneAbbr.includes('-') || zoneAbbr.includes('+')){
         zoneAbbr = `UTC${ offset }`;
      } else {
         zoneAbbr = `${ zoneAbbr }, UTC${ offset }`
      }
      return zoneAbbr
   }
   static getOffsetByUtc(date) {
      const converDate = this.convertToUserTz(date, 'Z');
      const splitArr = converDate.split(':')
      const hours = splitArr[0];
      const minutes = splitArr[1];
      if(minutes === '00'){
         return hours
      } else {
         return converDate
      }
   }

   static getDateByTimzeonFormat(date, withAt = true) {
      const offset = this.getOffsetByUtc(date);
      let zoneAbbr = this.getUserZoneAbbr();
      if(zoneAbbr.includes('-') || zoneAbbr.includes('+')){
         zoneAbbr = ''
      }
      let result = `${ this.convertToUserTz(date, 'MMM DD, YYYY') }`

      if(withAt){
         result += ` at ${ this.convertToUserTz(date, 'hh:mm A') } ${ zoneAbbr } (UTC${ offset })`
      }

      return result
   }
   static currentYears() {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(backendTimezone);
      return result.tz(userTimeZone).format('YYYY');
   }

   static convertToUtc(date, format = 'YYYY-MM-DD HH:mm:ss') {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(date, userTimeZone);
      return result.tz(backendTimezone).format(format);
   }

   static getTime(time, name, bool = false, isReplace = false)  {
      if(isReplace && time === 1){
         return `a ${ bool ? name[0] : name }`
      }
      if(bool){
         return `${ time }${ name[0] }`
      }
      let res = `${ time } ${ name }`
      if(time > 1){
         res = `${ res }s`
      }
      if(time === 0) {
         res = ''
      }
      return res
   }


   static getDateLeft(date, oneChar = false, textForNegativeResult){
      if(!date) {
         return '0 minutes'
      }

      const userTimeZone = momentTimezone.tz.guess()
      const userDate = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
      const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      let duration = moment.duration(userDate.diff(nowDate));

      const hours = duration.hours();
      const months = duration.months();
      const days = duration.days();
      const years = duration.years();
      const minutes = duration.minutes();
      let result = `${ this.getTime(minutes, 'minute', oneChar) }`
      let diffByDeys = result ? `${ this.getTime(days, 'day', oneChar) } ${ this.getTime(hours, 'hour', oneChar) } ${ oneChar ? '' : 'and' } ` + result : `${ this.getTime(days, 'day', oneChar) } ${ this.getTime(hours, 'hour', oneChar) } `
      let diffByHours = result ? `${ this.getTime(hours, 'hour', oneChar)  } ${ oneChar ? '' : 'and' } ` + result : `${ this.getTime(hours, 'hour', oneChar)  } `
      if(years > 0) {
         result = `${ this.getTime(years, 'year', oneChar) } ${ this.getTime(months, 'month', oneChar) } ` + diffByDeys
      } else if(months > 0) {
         result = `${ this.getTime(months, 'month', oneChar) } ` + diffByDeys
      } else if(days > 0) {
         result =  diffByDeys
      } else if(hours > 0) {
         result = diffByHours
      } else {
         if(minutes < 1 && minutes >= 0) result = textForNegativeResult || 'Less than a minute'
      }
      if(textForNegativeResult && duration.milliseconds() < 0){
         return textForNegativeResult;
      }
      return result
   }
   static getDiff(date){
      if(!date) {
         return '0 minutes'
      }
      const userTimeZone = momentTimezone.tz.guess()
      const userDate = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
      const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      let duration = moment.duration(nowDate.diff(userDate));

      const hours = duration.hours();
      const months = duration.months();
      const days = duration.days();
      const minutes = duration.minutes();
      // const seconds = duration.seconds();
      const seconds = nowDate.diff(userDate, 'seconds');
      return { hours, months, days, minutes, seconds }
   }
   static diff(first, second){
      const userTimeZone = momentTimezone.tz.guess()
      const firstDate = momentTimezone.tz(first, backendTimezone).tz(userTimeZone);
      const secondDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      // let duration = moment.duration(secondDate.diff(firstDate, 'seconds'));
      return secondDate.diff(firstDate, 'days', true);
   }
   static diff2(first){
      const userTimeZone = momentTimezone.tz.guess()
      const firstDate = momentTimezone.tz(first, backendTimezone).tz(userTimeZone);
      const secondDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      const seconds = firstDate.diff(secondDate, 'seconds', true)
      return seconds;
   }
   static timeAgo(date){
      const userTimeZone = momentTimezone.tz.guess()
      const dateTz = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
      const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      let duration = moment.duration(nowDate.diff(dateTz));

      const hours = duration.hours();
      const minutes = duration.minutes();
      if(hours > 1){
         return `${ this.getTime(hours, 'hour') } ago`;
      } else {
         if(minutes < 1 && minutes >= 0) return 'Less than a minute'
         return `${ this.getTime(minutes, 'minute') } ago`;
      }
   }
   static timeWithYearsAgo(date, isCorrect, minSecondsText, isReplace = false){
      const userTimeZone = momentTimezone.tz.guess()
      const dateTz = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
      const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      let duration = moment.duration(nowDate.diff(dateTz));
      const hours = duration.hours();
      const minutes = duration.minutes();
      const months = duration.months();
      const days = duration.days();
      const years = duration.years();
      if(years >= 1){
         return `${ this.getTime(years, 'year', false, isReplace) } ago`;
      }
      if(months >= 1){
         return `${ this.getTime(months, 'month', false, isReplace) } ago`;
      }
      if(days >= 1){
         return `${ this.getTime(days, 'day', false, isReplace) } ago`;
      }
      if(hours >= 1){
         let key = 'hour';
         if(isCorrect) key = 'hr';
         return `${ this.getTime(hours, key, false, isReplace) } ago`;
      } else {
         let key = 'minute';
         if(isCorrect) key = 'min';
         if(minutes < 1 && minutes >= 0) return minSecondsText || 'Less than a minute'
         return `${ this.getTime(minutes, key, false, isReplace) } ago`;
      }
   }

   static checkIsValid(date, format = 'YYYY-MM-DD HH:mm:ss') {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(date, backendTimezone);
      return result.tz(userTimeZone).isValid();
   }

   static getCurentTimeMilliseconds(){
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(userTimeZone);
      return result.tz(backendTimezone).valueOf();
   }

   static getDateLeftBySeconds(date, diffSeconds){
      if(!date) {
         return 0
      }
      const userTimeZone = momentTimezone.tz.guess()
      const userDate = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
      const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)
      const seconds = nowDate.diff(userDate, 'seconds');
      const time = diffSeconds - seconds
      let result = ''
      let hours = time / 3600;
      let mins = Math.floor((time % 3600) / 60);
      if(hours >= 1){
         result = `${ Math.floor(hours) } hours`
         if(mins !== 0) {
            result = result + ` ${ mins } minutes`
         }
      } else if(mins >= 1){
         result = mins === 1 ? 'a minutes' : `${ mins } minutes`
      }
      return result
   }
   static addDateWhitTz(date, diffSeconds){
      if(date){
         const userTimeZone = momentTimezone.tz.guess()
         const userDate = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);
         const nowDate = momentTimezone.tz(backendTimezone).tz(userTimeZone)

         const dateTime = userDate.add(diffSeconds, 'seconds');
         const diffBySecons = dateTime.diff(nowDate, 'seconds', true)
         const format = diffBySecons > 3601 ? 'MMM D, HH:mm a' : 'HH:mm a'
         return dateTime.format(format)
      }
   }
   static addDatev2(date, diffSeconds){
      if(date){
         const userTimeZone = momentTimezone.tz.guess()
         const userDate = momentTimezone.tz(date, backendTimezone).tz(userTimeZone);

         const dateTime = userDate.add(diffSeconds, 'seconds');
         return dateTime
      }
   }

   static convertToUserTzByYear(date, format1 = 'YYYY-MM-DD', format2 = 'hh:mm a') {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(date, backendTimezone);
      const result1 = result.tz(userTimeZone).format(format1);
      const result2 = result.tz(userTimeZone).format(format2);
      let isCurrentYear = true;
      if(result.tz(userTimeZone).format('YYYY') !== this.currentYears()) {
         isCurrentYear = false;
      }
      let resultDate = result1  + ', ' + result2;
      if(!isCurrentYear) {
         resultDate = resultDate + ', ' + result.tz(userTimeZone).format('YYYY');
      }
      let timezoneAbbr = '';
      try {
         timezoneAbbr = momentTimezone().tz(momentTimezone.tz.guess()).format('z');
      } catch (e) {

      }
      if(timezoneAbbr.length > 0) {
         resultDate += ` ${ timezoneAbbr }`
      }
      return resultDate;
   }
   static getUserTimezon() {
      return momentTimezone().tz(momentTimezone.tz.guess()).format('z');
   }

   static convertToUserTzWithAT(date) {
      return this.convertToUserTz(date, 'MMM DD, YYYY') + ' at ' + this.convertToUserTz(date, 'hh:mm A');
   }

   static getDate(date) {
      const currentDate = moment();
      const targetDate = moment(date);
      const daysDifference = currentDate.diff(targetDate, 'days');
      const currentMonthDays = moment(date).daysInMonth()


      if(daysDifference > currentMonthDays) {
         return 'More than 1 month ago'
      } else if(daysDifference > 7){
         return ' More than 1 week ago'
      } else if(daysDifference === 7){
         return '1 week ago'
      } else if(daysDifference > 3){
         return 'A few days ago'
      } else if(daysDifference > 1){
         return 'Yesterday'
      }

      return 'Today'

   }

   static calculateTimeLeft(targetDate) {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(targetDate, backendTimezone);
      const userDate = result.tz(userTimeZone).format();
      const now = moment();
      const target = moment(userDate, moment.ISO_8601);
      const difference = target.diff(now);

      if(difference > 0) {
         const duration = moment.duration(difference);

         let years = duration.years()
         let months = duration.months()
         let days = duration.days()
         let hours =  duration.hours()
         let minutes = duration.minutes()
         let seconds = duration.seconds()

         return `${ years ? `${ years }y` : '' } ${ months ? `${ months }m` : '' } ${ days ? `${ days }d` : '' } ${ hours ? `${ hours }h` : '' } ${ minutes ? `${ minutes }m` : '' } ${ seconds ? `${ seconds }s` : '' }`
      }

      return null
   }
   static calculateTimeLeft2(targetDate) {
      const userTimeZone = momentTimezone.tz.guess()
      const result = momentTimezone.tz(targetDate, backendTimezone);
      const userDate = result.tz(userTimeZone).format();
      const now = moment();
      const target = moment(userDate, moment.ISO_8601);
      const difference = target.diff(now);

      if(difference > 0) {
         const duration = moment.duration(difference);
         let days = duration.days()
         let hours =  duration.hours()
         let minutes = duration.minutes()
         let seconds = duration.seconds();
         if(days >= 1){
            return this.getTime(days, 'day');
         }
         if(hours >= 1){
            return this.getTime(hours, 'hr');
         }
         if(minutes >= 1){
            return this.getTime(minutes, 'min');
         }
         if(seconds >= 1){
            return this.getTime(seconds, 'sec');
         }
      }

      return null
   }

   static calculateTimeAgo(targetDate) {
      const userTimeZone = momentTimezone.tz.guess()
      const target = momentTimezone.tz(targetDate, backendTimezone).tz(userTimeZone);
      const now = momentTimezone.tz(backendTimezone).tz(userTimeZone)

      const difference = target.diff(now);

      if(difference < 0) {
         const duration = moment.duration(Math.abs(difference));

         const years = duration.years()
         const months = duration.months()
         const days = duration.days();
         const hours = duration.hours();
         const minutes = duration.minutes();
         const seconds = duration.seconds();

         if(years > 0) {
            return `${ years }y`
         }
         if(months > 0) {
            return `${ months }mo`
         }
         if(days > 0) {
            return `${ days }d`
         }
         if(hours > 0) {
            return `${ hours }h`
         }
         if(minutes > 0) {
            return `${ minutes }m`
         }
         if(seconds > 0) {
            return `Just now`
         }
      }

      return null;
   }

   static getDateLeftInDays(date, hideSeconds = false) {
      if(!date) {
         return '0 minutes'
      }
      const userTimezone = momentTimezone.tz.guess();

      const targetDate = moment.utc(date);

      const currentDate = moment().tz(userTimezone);

      const duration = moment.duration(targetDate.diff(currentDate));

      const daysLeft = Math.floor(duration.asDays());
      const hoursLeft = duration.hours();
      const minutesLeft = duration.minutes();
      const secondsLeft = duration.seconds();

      let result = []

      if(daysLeft > 0) {
         result.push(`${ daysLeft }d`)
      }
      if(hoursLeft > 0) {
         result.push(`${ hoursLeft }h`)
      }
      if(minutesLeft > 0) {
         result.push(`${ minutesLeft }m`)
      }
      if(secondsLeft > 0) {
         if(hideSeconds && result.length === 0) {
            return 'Less then a minute'
         }
         if(!hideSeconds) {
            result.push(`${ secondsLeft }s`)
         }
      }

      return result.join(' ');

   }

   static addHoursToDate(targetDate, hours = 24) {
      const result = moment.utc(targetDate).add(hours, 'hours')
      return result.format()
   }
   static isWeekend() {
      const userTimezone = momentTimezone.tz.guess();
      // Current date and time in the specified timezone
      const now = moment().tz(userTimezone);

      // Get the next Friday at 03:00 PM
      const weekendStart = moment().tz(userTimezone).day(3).hour(15).minute(0).second(0).millisecond(0);

      const weekendEnd = moment().tz(userTimezone).day(7).hour(23).minute(59).second(59).millisecond(999);
      let nextMonday = moment().tz(userTimezone).day(1).hour(0).minute(0).second(0).millisecond(0);
      if(nextMonday.isBefore(now)) {
         nextMonday.add(7, 'days');
      }
      // console.log(weekendStart.format('MMM DD hh:mm a'))
      // console.log(weekendEnd.format('MMM DD hh:mm a'))
      // console.log(nextMonday.format('MMM DD hh:mm a'), moment().tz(userTimezone).day())
      // Check if the current time falls between Friday 03:00 PM and Sunday 11:59 PM
      if(now.isBetween(weekendStart, weekendEnd)) {
         console.log("It's the weekend!");
         return true; // It's weekend
      } else {
         console.log("It's not the weekend.");
         return false; // It's not weekend
      }
   }
}
export default DateFormatter
