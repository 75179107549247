const ALLOWED_SITES = [
   'cretrix',
];

export const isLocalhost = () => {
   return Boolean(
      window.location.hostname === 'localhost'
        // [::1] is the IPv6 localhost address.
        || window.location.hostname === '[::1]'
        // 127.0.0.1/8 is considered localhost for IPv4.
        || window.location.hostname.match(
           /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
        || window.location.hostname.match(
           /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
   );
};
export const isStaging = () => {
   return Boolean(
      window.location.href.includes('amstest')
   );
};

export const getSiteUrl = () => {
   const {
      hostname,
      pathname,
   } = window.location;
   if(hostname === process.env.REACT_APP_AMS_DOMAIN) {
      return `${ hostname }/${ pathname.split('/').filter(Boolean)[0] }`;
   }
   return hostname;
};
export const getSiteUrlHTML = () => {
   const {
      hostname,
      pathname,
   } = window.location;
   if(hostname === 'mymember.site') {
      return `my<span style="font-weight: bold;">member.</span>site/${ pathname.split('/').filter(Boolean)[0] }`;
   }
   return null;
};

export const isFriskybussiness = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return '87a72e653bfb4a83833b77b97ff50d6f' === appKey;
}
export const isLukespov = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return '880a8f501b9bf33c272b30fe725f4c79' === appKey;
}

export const isRiggsfilms = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return '9b3d2f6b90e74830a004fd738d0c2a14' === appKey;
}
export const isShemalevalentina = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return 'bd629a663e104724a1dde07384d259af' === appKey;
}

export const getSiteUUid = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return appKey;
}
export const isAllowedSites = () => {
   const node = document.querySelector('meta[name=subdomain]');
   let subdomain = null;
   if(node) {
      subdomain = node.content;
   }
   return ALLOWED_SITES.includes(subdomain);
}
export const isMixedWrestlingHeaven = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return '8d4121f3e7f442c49547845948258f53' === appKey;
}

export const isHavenMoss = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return 'e6be69d39056494f8da18d9c318a3c6b' === appKey;
}
export const isStrongGirlsRule = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }

   return '30580861bb674cf28586b56c9c7fb670' === appKey;
}
export const isSisterhood = () => {
   const node = document.querySelector('meta[name=appKey]');
   let appKey = null;
   if(node) {
      appKey = node.content;
   }
   return 'bc557d764ead431a8ea18677bd7bf409' === appKey;
}

export const isDrmTokenBasedLicenseIssuanceForMember = (memberUuid = '') => {
   return [
      'a34586f6375548bd0092fced48e39164', // Staging member UUID for test
      'ea001a22fa94c500f63b34e236088769', // secret...project -> mhbusiness900 member
   ].includes(memberUuid)
}
