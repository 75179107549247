import { filterBadWords } from 'common/utils/filter-bad-words';
import React from 'react';
import PropTypes from 'react';

const CookiePolicy = ({
   legalPages,
   isAgeVerificationRequired,
}) => {

   let currentInfo

   if(!!legalPages && !!legalPages?.length) {
      currentInfo = legalPages?.find(it => it.name === 'cookie_policy')
   }
   return (
      currentInfo?.html_content ?
         <>
            <div dangerouslySetInnerHTML={ {
               __html: isAgeVerificationRequired ? filterBadWords(currentInfo?.html_content) : currentInfo?.html_content,
            } } className='text-major dark:text-major-dark policy-list-dist ql-editor'
            />
         </>
         :
         <>

            <p className='dark:text-secondary-dark '>
               We value your privacy and are committed to being transparent about how we use cookies on our website. This
               policy explains what cookies are, how we use them, and your options regarding their use.<br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>What Are Cookies?</p>
            <p className='dark:text-secondary-dark '>
               Cookies are small text files stored on your device when you visit a website. They help us improve your
               experience by remembering preferences, enabling essential functionalities, and gathering insights to enhance
               our services.<br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>Types of Cookies We Use</p>
            <ul style={ { paddingLeft: '30px', listStyle: 'auto' } }>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Essential Cookies</span><br />
                  These cookies are necessary for the website to function and cannot be disabled. They support core
                  features such as secure account login and session management.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Analytics Cookies</span><br />
                  We use Google Analytics to gather anonymized data about how users interact with our site. This helps
                  us improve functionality and optimize your experience.
               </li>
               <li style={ { margin: '4px 0' } }>
                  <span className='font-medium'>Functionality Cookies</span><br />
                  These cookies enhance website performance and user experience by remembering your preferences
                  and enabling features like social login buttons.
               </li>
            </ul>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>What We Don’t Use</p>
            <span className='font-medium'>No Advertising Cookies</span>
            <p>We do not use cookies for advertising purposes, and we do not track your
               activities across other websites.</p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>Consent for Cookies</p>
            <p className='dark:text-secondary-dark '>
               By using this site, you agree to our use of cookies. We use an implied consent model, meaning that by
               continuing to browse or use our site, you consent to the use of cookies as described in this policy.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>Data Collection and Sharing</p>
            <ul style={ { paddingLeft: '30px', listStyle: 'disc' } }>
               <li style={ { margin: '4px 0' } }>
                  We only collect data necessary to facilitate account login and ensure basic website functionality.
               </li>
               <li style={ { margin: '4px 0' } }>
                  We do not share your personal information with third parties, except as required for secure payment
                  processing.
               </li>
            </ul>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>Managing Cookies</p>
            <p className='dark:text-secondary-dark '>
               Currently, we do not offer options to customize or disable cookies on our website. However, you can control
               cookies through your browser settings. Please note that disabling cookies may impact the functionality of our
               website.
               <br /><br />
            </p>
            <p className='text-2xl text-major dark:text-major-dark mb-3 mt-6 font-bold' style={ { lineHeight: '28px' } }>Commitment to Privacy</p>
            <p className='dark:text-secondary-dark '>
               We are a privacy-focused platform and prioritize your trust. Your data will never be sold or shared with
               unauthorized third parties. For more information, refer to our <a
                  href='?information-and-policies=privacy-policy' rel='noopener noreferrer' target='_blank'>Privacy Policy</a>.
               <br /><br />
            </p>
         </>
   )
};

CookiePolicy.propTypes = {
   legalPages: PropTypes.array,
   isAgeVerificationRequired: PropTypes.bool,
}


export default CookiePolicy;
