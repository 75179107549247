import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import IconLabel from 'common/components/elements/icon-label';
import cx from 'classnames'

const ActionModalContent = ({
   action,
   isLoading,
   disabled,
   title,
   children,
   icon,
   iconColor,
   buttonText = 'Discard changes',
   buttonClassName = 'mt-8',
   className = '',
   closeModal,
   secondButtonTitle,
   secondButtonClassName,
   secondButtonIconName,
   buttonIconName,
   secondButtonAction,
   secondButtonDisabled,
   headerClass = 'mb-4',
   secondButtonPosition = 'top',
   withoutCloseButton,
   buttonBackgroundColor,
   withoutConfirmButton,
   closeBtnClassName = '!w-[fit-content]',
   overrideSecondButtonProps = {},
   buttonTextClassName,
   titleClassName,
}) => {
   let secondButton = null;
   if(secondButtonTitle){
      secondButton = (
         <div className={ `h-9 ${ disabled ? 'opacity-50' : '' } ${ secondButtonClassName } w-full` }>
            <Button
               text={ secondButtonTitle }
               borderRadius='large'
               onClick={ secondButtonAction }
               padding='none'
               classNames='h-full w-full'
               fontWeight='medium'
               iconName={ secondButtonIconName }
               textSize='small'
               textColor='secondary dark:text-secondary-dark'
               fontIconColor='secondary'
               darkIconColor='secondary-dark'
               backgroundColor='transparent'
               iconPosition='left'
               iconSize='small'
               disabled={ secondButtonDisabled }
               fontIconSize='extraLarge'
               { ...overrideSecondButtonProps }
            />
         </div>
      )
   }
   return (
      <>
         {
            !withoutCloseButton && (
               <div className={ `h-10 w-10 min-w-[40px] flex items-center justify-center my-2 mr-2 ml-auto ${ closeBtnClassName }` }>
                  <Button
                     iconName='close'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     backgroundColor='transparent'
                     onClick={ closeModal }
                     padding='none'
                     classNames='w-5 h-5'
                  />
               </div>

            )
         }
         <div
            className={ cx({
               [`flex flex-col ${ className } modal-confiramtion`]: true,
               'p-6': withoutCloseButton,
               'px-6 pb-6': !withoutCloseButton,
            }) }
         >
            <div className={ `flex items-center flex-col ${ headerClass }` }>
               {
                  icon && (
                     <IconLabel
                        color={ iconColor }
                        isPrimary={ !iconColor }
                        name={ icon }
                        size='2xl'
                        className='h-10 w-10 mb-3'
                     />
                  )
               }
               <span className={ `text-major dark:text-major-dark text-xl font-semibold leading-[30px] cursor-default ${ titleClassName }` }>{title}</span>

            </div>
            {
               children && children
            }
            {
               secondButtonTitle && 'top' === secondButtonPosition && (
                  secondButton
               )
            }
            {
               !withoutConfirmButton && (
                  <div className={ `h-9 ${ disabled ? 'opacity-50' : '' } ${ buttonClassName } w-full` }>
                     <Button
                        text={ buttonText }
                        borderRadius='large'
                        textSize='small'
                        onClick={ action }
                        padding='none'
                        classNames='h-full w-full'
                        isLoading={ isLoading }
                        primaryColor
                        fontWeight='medium'
                        disabled={ disabled }
                        iconName={ buttonIconName }
                        iconPosition='left'
                        iconSize='small'
                        fontIconSize='extraLarge'
                        backgroundColor={ buttonBackgroundColor }
                        textClassName={ buttonTextClassName }
                     />
                  </div>
               )
            }
            {
               secondButtonTitle && 'bottom' === secondButtonPosition && (
                  secondButton
               )
            }
         </div>
      </>
   );
}


ActionModalContent.propTypes = {
   action: PropTypes.func,
   closeModal: PropTypes.func,
   isLoading: PropTypes.bool,
   disabled: PropTypes.bool,
   title: PropTypes.string,
   children: PropTypes.any,
   icon: PropTypes.string,
   iconColor: PropTypes.string,
   buttonText: PropTypes.string,
   buttonClassName: PropTypes.string,
   className: PropTypes.string,
   secondButtonClassName: PropTypes.string,
   secondButtonTitle: PropTypes.string,
   buttonIconName: PropTypes.string,
   secondButtonDisabled: PropTypes.bool,
   secondButtonIconName: PropTypes.string,
   headerClass: PropTypes.string,
   secondButtonAction: PropTypes.func,
   secondButtonPosition: PropTypes.string,
   withoutCloseButton: PropTypes.bool,
   buttonBackgroundColor: PropTypes.string,
   withoutConfirmButton: PropTypes.bool,
   closeBtnClassName: PropTypes.string,
   overrideSecondButtonProps: PropTypes.object,
   buttonTextClassName: PropTypes.string,
   titleClassName: PropTypes.string,
};


export default ActionModalContent;
