import { createSelector } from 'reselect';

const innerStateSelector = state => state.polls

const pollDataSelector = createSelector(
   innerStateSelector,
   state => state.pollData
)
const isFetchingPollDataSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingPollData
)
const pollModalSelector = createSelector(
   innerStateSelector,
   state => state.pollModal
)
const isFetchingSubmitVoteSelector = createSelector(
   innerStateSelector,
   state => state.isFetchingSubmitVote
)
const pollConfirmDialogSelector = createSelector(
   innerStateSelector,
   state => state.pollConfirmDialog
)
export {
   pollDataSelector,
   isFetchingPollDataSelector,
   pollModalSelector,
   isFetchingSubmitVoteSelector,
   pollConfirmDialogSelector,
}
