import React, { Component } from 'react';
// import { createPortal } from 'react-dom';
import Routes from 'sites/routes';
import axios from 'axios';
import LocalStorage from 'sites/utils/LocalStorage';
import { history } from "sites/state/store";
// import AddToHomeScreenContainer from 'sites/containers/modules/add-to-home-screen'; //TO DO REMOVE PWA
import AgeVerificationContainer from 'sites/containers/modules/age-verification';

import QueryParams from 'sites/utils/QueryParams';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { magicLogin }  from 'sites/api/GuestApi';
import Auth from 'sites/utils/Auth';
import moment from 'moment';
import {
   authUserSelector,
   siteNameSelector,
   siteInfoSelector,
   isSiteInitedSelector,
   isAgeVerificationRequiredSelector,
} from 'sites/state/modules/common/selectors';

import {
   currentLocationPathSelector,
} from 'sites/state/modules/router/selectors';
import { initMemberAreaSocket } from 'common/socket';
import toster from 'common/utils/toast';
import logout from 'sites/utils/logout';
import { isAllowedRouteIfNeedAgeVerification, isNonHaveAdultContent } from 'sites/utils/utils';
import ViewAs from 'sites/containers/pages/view-as';
import LiveStreamPopup from 'sites/containers/modules/live-stream-popup';
import { getProtocol } from 'common/utils/utils';
import RebrandingModal from 'common/components/modules/rebranding-modal';
import Router from 'sites/routes/router';
import PollsContainer from 'sites/containers/modules/polls'
import NSFWContentWarningModal from './views/age-verification-by-id/nsfw-content-warning-modal';
import { setShowNSFWModalAction } from './state/modules/common/actions';

class App extends Component {
   static propTypes = {
      logout: PropTypes.func.isRequired,
      authUser: PropTypes.object,
      siteInfo: PropTypes.object,
      isSiteInited: PropTypes.bool,
      siteName: PropTypes.any,
      isAgeVerificationRequired: PropTypes.bool,
      setShowNSFWModal: PropTypes.func,
   };

   historyUnlistener = null;
   swCheckRequestInProgress = false;

   historyUnblock = null;

   state = {
      needToRender: (false === !!QueryParams.getParam('m_l_t')),
      showRebrandingModal: false,
      isSentInitialTraffic: false,
   };

   componentDidUpdate(prevProps, prevState) {
      const { isSentInitialTraffic } = this.state;
      this.checkForEpochPayment();
      this.checkForSocket((socket) => {
         if(!isSentInitialTraffic && socket) {
            socket.emit('traffic_source:update', this.getTrafficSourceData(window.location))
            this.setState({
               isSentInitialTraffic: true,
            })
         }
      });
   }

   checkForSocket = (callback) => {
      const { authUser, siteName, logout } = this.props;
      if(siteName && !this.socket) {
         this.socket = initMemberAreaSocket(siteName, true);
         window.memberAreaSocket = this.socket
         if(callback) {
            callback(this.socket)
         }
      }
      if(authUser && this.socket) {
         this.socket.removeAllListeners('member.block');

         this.socket.on('member.block', member => {
            const {
               member_id,
               banned,
            } = member
            if(authUser && member_id.toString() === authUser.id.toString() && banned) {
               logout();
               setTimeout(() => {
                  toster.remove('Please contact support');
                  history.push(Router.route('ERROR_PAGE').getCompiledPath())
               }, 100);
            }
         });
      }
   }


   getTrafficSourceData = (location) => {
      const { authUser } = this.props

      return {
         current_url: location.pathname,
         referer_url: document.querySelector('meta[name=referer]')?.content || 'unknown',
         member_id: authUser?.id || null,
         ever_subscribed: authUser?.ever_subscribed || false,
      }
   }

   componentDidMount() {
      const showRebrandingModal = QueryParams.getParam('showRebrandingModal');
      if(showRebrandingModal){
         this.setState({ showRebrandingModal: true })
      }

      this.checkForSocket();

      history.listen((location, action) => {
         if('PUSH' === action && this.socket) {
            const data = this.getTrafficSourceData(location)
            this.socket.emit('traffic_source:update', data)
         }
      })

      this.historyUnblock = history.block(({ pathname }, action) => {
         const { isAgeVerificationRequired, setShowNSFWModal } = this.props

         if(!isAgeVerificationRequired) {
            return true
         }

         const isAllowRouteChange = isAllowedRouteIfNeedAgeVerification(pathname)

         if(isAllowRouteChange) {
            return true
         } else {
            setShowNSFWModal(true)
            return false
         }
      })

      const magicToken = QueryParams.getParam('m_l_t');
      if(magicToken) {
         magicLogin({
            token: magicToken,
         }).then((data) => {
            if(data.data && data.data.success) {
               const token = data.data.token;
               LocalStorage.setItem('bypassSetTime', moment().utc().unix());
               Auth.setToken(token);
            }
            this.setState({
               needToRender: true,
            });
         }).catch((err) => {
            this.setState({
               needToRender: true,
            });
         })
         return;
      }


      this.checkForEpochPayment();
      if('production' !== process.env.NODE_ENV) {
         return;
      }
      const client = axios.create();
      let swHashUrl = '/sw.js?hash=1';
      if('site_name_prefix' === document.querySelector('meta[name=domainType]').content) {
         swHashUrl = `/${ document.querySelector('meta[name=subdomain]').content }${ swHashUrl }`;
      }

      if(!LocalStorage.getItem('swHash')) {
         client.get(swHashUrl).then((response) => {
            const { data } = response;
            const swHash = data.hash;
            if(swHash) {
               LocalStorage.setItem('swHash', swHash);
               window.location.reload(true);
            }
         }).catch(() => {});
      }
      this.historyUnlistener = history.listen(() => {
         if(this.swCheckRequestInProgress || !LocalStorage.getItem('swHash')) {
            return;
         }
         this.swCheckRequestInProgress = true;
         client.get(swHashUrl).then((response) => {
            const { data } = response;
            const swHash = data.hash;
            if(swHash && swHash !== LocalStorage.getItem('swHash')) {
               navigator.serviceWorker.ready.then(registration => {
                  registration.unregister();
               });
               setTimeout(() => {
                  LocalStorage.setItem('swHash', swHash);
                  window.location.reload(true);
               }, 50);
            } else {
               this.swCheckRequestInProgress = false;
            }
         }).catch(() => {
            this.swCheckRequestInProgress = false;
         });
      });
   }

   componentWillUnmount() {
      if(this.historyUnlistener) {
         this.historyUnlistener();
      }
   }

   checkForEpochPayment() {
      const ans = QueryParams.getParam('ans');
      const baseUrl = `${ getProtocol() }redirect.${ process.env.REACT_APP_AMS_DOMAIN }`;
      let redirectUrl;
      if('string' === typeof ans) {
         switch (ans[0]) {
            case 'Y':
               redirectUrl = `${ baseUrl }/payment-approved`;
               break;
            case 'N':
               redirectUrl = `${ baseUrl }/payment-declined`;
               break;
            default:
               break;
         }
         if(redirectUrl) {
            window.location.href = redirectUrl;
         }
      }
   }

   onClosesRebrandingModal = () => {
      this.setState({ showRebrandingModal: false })
      QueryParams.removeQueryParam('showRebrandingModal')
   }


   render() {
      const { needToRender, showRebrandingModal } = this.state;
      const { siteInfo, isSiteInited } = this.props;
      if(!needToRender) {
         return null;
      }
      return (
         <>

            <div id='promo'></div>
            {
               isSiteInited && (
                  <>
                     <ViewAs />
                     <PollsContainer />
                  </>
               )
            }
            <Routes />
            {
               !isNonHaveAdultContent(siteInfo.is_site_adult) && (
                  <AgeVerificationContainer />

               )
            }
            {/* {
               createPortal(
                  <AddToHomeScreenContainer />,
                  document.getElementById('addToHomeScreenPrompts')
               )
            } */}
            <LiveStreamPopup />
            {
               showRebrandingModal && (
                  <RebrandingModal
                     onCloseModal={ this.onClosesRebrandingModal }
                  />
               )
            }
            <NSFWContentWarningModal />

         </>
      );
   }
}
const mapStateToProps = (state) => {
   return {
      authUser: authUserSelector(state),
      siteInfo: siteInfoSelector(state),
      siteName: siteNameSelector(state),
      isSiteInited: isSiteInitedSelector(state),
      locationPath: currentLocationPathSelector(state),
      isAgeVerificationRequired: isAgeVerificationRequiredSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      logout: logout(dispatch),
      setShowNSFWModal: (bool) => dispatch(setShowNSFWModalAction(bool)),
   }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
